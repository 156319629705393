
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import _ from 'lodash'
import CustomConfirm from './partials/CustomConfirm.vue'
import FteChart from './partials/FteChart.vue'
import FiltersComponent from './partials/FiltersComponent.vue'
import { ElMessage } from '@/../node_modules/element-plus/es/components'
import { Specialty } from '@/interfaces/specialty'
import { Territory, TerritoryFte, Team } from '@/interfaces/project'
import { Segment } from '@/interfaces/segment'
import { CustomerGroup } from '@/interfaces/customergroup'
import { SegmentType } from '@/store/modules/@types/entity'

export default defineComponent({
  setup () {
    const store = useStore()

    const all = store.getters.territoryFtes

    const values = []
    const labels = []

    all.forEach((t) => {
      values.push(t.fte.toFixed(2))
      labels.push(t.territory)
    })

    const reactiveObj = reactive({
      activeNames: ['2', '3', '4'],

      gsmiChart: false,
      loadingValues: false,
      territoriesFiltered: store.getters.territories['hydra:member'],
      segmentsFiltered: store.getters.segments['hydra:member'],

      filter: {
        salesTeams: store.getters.salesTeams.map((s) => s.id),
        territories: store.getters.territories.map((s) => s.id),
        segments: store.getters.segments.map((s) => s.id),
        customerGroups: store.getters.customerGroups.map((s) => s.id)
      },

      chartData: {
        datasets: values,
        labels
      },

      newSegment: {
        type: _.snakeCase(SegmentType.GAIN),
        name: '',
        max: 0,
        min: 0,
        targetedCustomers: 0,
        callsYearly: 0
      }
    })

    return toRefs(reactiveObj)
  },

  mounted: function () {
    this.filter.salesTeams = this.$store.getters.salesTeams.map((s) => s.id)
    this.filter.territories = this.$store.getters.territories.map((s) => s.id)
    this.filter.segments = this.$store.getters.segments.map((s) => s.id)
    this.filter.customerGroups = this.$store.getters.customerGroups.map((s) => s.id)
  },

  components: {
    FteChart,
    FiltersComponent,
    CustomConfirm
  },

  methods: {
    updateProject: function () {
      this.$store.dispatch('UPDATE_PROJECT', {
        daysInField: this.$store.getters.project.daysInField,
        callsDaily: this.$store.getters.project.callsDaily,
        projectId: this.$store.getters.project.id
      }).then(() => {
        this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })
      }).catch(() => {
        this.$store.dispatch('LOAD_PROJECT_INFO', {
          projectId: this.$store.getters.project.id
        }).then(() => {
          this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })
        })
      })
    },

    getSegmentTypes: function () {
      const values = []

      for (const item in SegmentType) {
        values.push({ label: _.upperCase(item).match(/\b(\w)/g).join(''), value: _.lowerCase(item) })
      }

      return values
    },

    getSegmentType: function (type: string): string {
      return _.upperCase(type).match(/\b(\w)/g).join('')
    },

    updateChartData: function (): void {
      const values = []
      const labels = []

      if (!this.gsmiChart) {
        const all = this.territoriesFiltered

        if (typeof all !== 'undefined') {
          all.forEach((t) => {
            values.push(t.fte.toFixed(2))
            labels.push(t.territory)
          })
        }
      } else {
        const all = this.segmentsFiltered

        if (typeof all !== 'undefined') {
          all.forEach((s) => {
            if (s.type !== 'not_targeted') {
              values.push(s.fTEs.toFixed(2))
              labels.push(s.name)
            }
          })
        }
      }

      this.chartData.labels = labels
      this.chartData.datasets = values
    },

    getSummaries: function (param) {
      const { columns } = param

      const sums = []

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'TOTAL'
          return
        }

        if ([1, 2, 3].includes(index)) {
          sums[index] = ''
          return
        }

        const values = this.$store.getters.segments.map((item) => {
          if (column.property.indexOf('.') >= 0) {
            const [p, r] = column.property.split('.')

            return Number(item[p][r])
          }

          return Number(item[column.property])
        })

        if (!values.every((value) => isNaN(value))) {
          sums[index] = new Intl.NumberFormat().format(_.sum(values))
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    },

    updateFilter: function (filter) {
      this.filter = filter
      this.page = 1
      this.loadingValues = true

      if (typeof this.$store.getters.project === 'undefined' || typeof this.$store.getters.project?.id === 'undefined') {
        this.loadingValues = false
        return
      }

      const values = {
        projectId: this.$store.getters.project?.id,
        filter,
        page: parseInt('' + this.page),
        perPage: parseInt('' + this.pageSize),
        sort: this.sort,
        sortDirection: this.order
      }

      const waitForUpdate = setInterval(() => {
        if (this.$store.getters.specialtiesWereUpdated === true) {
          this.$store.dispatch('LOAD_TERRITORY_FTES_FILTERED', values)
            .then((res) => {
              this.territoriesFiltered = res['hydra:member']
              this.updateChartData()
            })

          this.$store.dispatch('LOAD_SEGMENTS_FILTERED', values)
            .then((res) => {
              this.segmentsFiltered = res['hydra:member']
              this.loadingValues = false
            })

          clearInterval(waitForUpdate)
        }
      }, 500)
    },

    resetFilter: function () {
      const filter = {
        salesTeams: this.$store.getters.salesTeams.map((s) => s.id),
        territories: this.$store.getters.territories.map((s) => s.id),
        segments: this.$store.getters.segments.map((s) => s.id),
        customerGroups: this.$store.getters.customerGroups.map((s) => s.id)
      }

      this.filter = filter
      this.updateFilter(filter)
    },

    updateSegment: function (row) {
      if (row.name === '') {
        ElMessage.error('Segment name should not be empty')
        return
      }

      this.$store.dispatch('UPDATE_SEGMENT', row)
        .then(() => {
          this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })
        })
    },

    deleteSegment: function (id: string) {
      this.$store.dispatch('DELETE_SEGMENT', {
        id
      }).then(() => {
        this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })
      })
    },

    addSegment: function () {
      if (this.$store.getters.segments.length > 0) {
        const lastSegment = this.$store.getters.segments[this.$store.getters.segments.length - 1]
        this.newSegment.max = parseFloat(lastSegment.min) ?? 0
      } else {
        this.newSegment.max = 100
      }

      if (this.newSegment.name === '') {
        ElMessage.error('Segment name should not be empty')
        return
      }

      this.$store.dispatch('ADD_SEGMENT', {
        ...this.newSegment,
        project: process.env.VUE_APP_IRI_PREFIX + 'projects/' + this.$store.getters.project.id
      }).then(() => {
        this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })

        this.newSegment = {
          type: _.snakeCase(SegmentType.GAIN),
          name: '',
          max: 0,
          min: 0,
          targetedCustomers: 0,
          callsYearly: 0
        }
      })
    },

    sumFteNeeded: function (): string {
      let sum = 0

      if (typeof this.segmentsFiltered !== 'undefined') {
        this.segmentsFiltered.forEach((segment) => {
          sum += parseFloat(segment.fTEs)
        })
      }

      const num = Math.round(sum * 10) / 10
      return new Intl.NumberFormat().format(num)
    },

    sumCallsCapacityNeeded: function (): string {
      let sum = 0

      if (typeof this.segmentsFiltered !== 'undefined') {
        this.segmentsFiltered.forEach((segment) => {
          sum += segment.callsCapacity
        })
      }

      const num = Math.round(sum)
      return new Intl.NumberFormat().format(num)
    }
  },

  watch: {
    segments: function (value) {
      this.updateChartData()
      this.filter.segments = value?.map((s) => s.id)
    },

    territoryFtes: function () {
      this.updateChartData()
    },

    specialties: function (value) {
      this.filter.specialties = value?.map((s) => s.id)
    },

    salesTeams: function (value) {
      this.filter.salesTeams = value?.map((s) => s.id)
    },

    territories: function (value) {
      this.filter.territories = value?.map((s) => s.id)
    },

    customerGroups: function (value) {
      this.filter.customerGroups = value?.map((s) => s.id)
    },

    gsmiChart: function () {
      this.updateChartData()
    },

    segmentsFiltered: function (value) {
      const c = value.length - 1

      if (c !== -1 && value[c].name === 'LAST_SEGMENT_SYS_ADD') {
        return
      }

      if (c >= 0) {
        this.segmentsFiltered.push({
          '@id': 'null',
          '@type': 'LAST_SEGMENT_SYS_ADD',
          callsCapacity: 0,
          callsYearly: this.segmentsFiltered.map((s) => s.callsYearly).reduce((prev, next) => prev + next),
          fTEs: 0,
          id: 'null',
          max: 0,
          min: 0,
          name: 'SYS_SUM_SEGMENTS',
          project: this.$store.getters.project.id,
          targetedCustomers: this.segmentsFiltered.map((s) => s.targetedCustomers).reduce((prev, next) => prev + next),
          type: 'not_targeted'
        })
      }

      this.segmentsFiltered.push({
        '@id': 'null',
        '@type': 'LAST_SEGMENT_SYS_ADD',
        callsCapacity: 0,
        callsYearly: 0,
        fTEs: 0,
        id: 'null',
        max: 0,
        min: 0,
        name: 'LAST_SEGMENT_SYS_ADD',
        project: this.$store.getters.project.id,
        targetedCustomers: 0,
        type: 'not_targeted'
      })
    }
  },

  computed: {
    callsPerYear: function (): number {
      return Math.round(this.$store.getters.project.callsYearly)
    },

    segments: function (): Array<Segment> {
      return this.$store.getters.segments
    },

    territoryFtes: function (): Array<TerritoryFte> {
      return this.$store.getters.territoryFtes
    },

    specialties: function (): Array<Specialty> {
      return this.$store.getters.specialties
    },

    salesTeams: function (): Array<Team> {
      return this.$store.getters.salesTeams
    },

    territories: function (): Array<Territory> {
      return this.$store.getters.territories
    },

    customerGroups: function (): Array<CustomerGroup> {
      return this.$store.getters.customerGroups
    },

    isEditable: function () {
      const filters = this.filter

      return (filters.customerGroups.length === this.$store.getters.customerGroups.length &&
        filters.salesTeams.length === this.$store.getters.salesTeams.length &&
        filters.segments.length === this.$store.getters.segments.length &&
        filters.territories.length === this.$store.getters.territories.length)
    }
  }
})
