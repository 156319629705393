
import { defineComponent } from 'vue'
import { Bar } from 'vue3-chart-v2'

export default defineComponent({
  name: 'FteChart',
  extends: Bar,

  props: {
    chartData: {
      type: Object,
      required: true
    },

    isGsmi: {
      type: Boolean,
      required: true
    }
  },

  mounted () {
    this.renderMethod(this.chartData, true)
  },

  watch: {
    chartData: {
      handler: function (value) {
        this.renderMethod(value, true)
      },
      deep: true
    }
  },

  methods: {
    renderMethod: function (data, showLine: boolean) {
      const datasets = []
      const lineValues = []
      let suggestedMax = 1.5

      data.labels.forEach(() => {
        lineValues.push(1)
      })

      if (!this.isGsmi || (typeof showLine !== 'undefined' && showLine)) {
        datasets.push({
          type: 'line',
          data: lineValues,
          fill: false,
          borderColor: '#409EFF',
          borderDashOffset: 5,
          borderDash: [20, 5],
          borderWidth: 1,
          tension: 0.1,
          pointRadius: 0
        })
      } else {
        suggestedMax = 0

        data.datasets.forEach((d) => {
          if (suggestedMax < d) {
            suggestedMax = d
          }
        })

        suggestedMax *= 2
      }

      datasets.push({
        type: 'bar',
        label: 'FTEs',
        data: data.datasets,
        backgroundColor: '#F56C6C'
      })

      this.renderChart({
        labels: data.labels,
        datasets
      }, {
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 0, // minimum will be 0, unless there is a lower value.
              beginAtZero: true, // minimum value will be 0.
              suggestedMax
            }
          }]
        },
        responsive: true,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        title: {
          display: false
        },
        animation: {
          duration: 0,
          onProgress: function () {
            const ctx = this.chart.ctx
            ctx.fillStyle = this.chart.config.options.defaultFontColor
            ctx.textAlign = 'center'
            ctx.textBaseline = 'bottom'
            this.data.datasets.forEach(function (dataset) {
              if (typeof dataset !== 'undefined' && typeof dataset._meta !== 'undefined' && dataset.type === 'bar') {
                for (let i = 0; i < dataset.data.length; i++) {
                  const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                  ctx.fillText(dataset.data[i], model.x, model.y - 5)
                }
              }
            })
          },
          onComplete: function () {
            const ctx = this.chart.ctx
            ctx.fillStyle = this.chart.config.options.defaultFontColor
            ctx.textAlign = 'center'
            ctx.textBaseline = 'bottom'
            this.data.datasets.forEach(function (dataset) {
              if (typeof dataset !== 'undefined' && typeof dataset._meta !== 'undefined' && dataset.type === 'bar') {
                for (let i = 0; i < dataset.data.length; i++) {
                  const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                  ctx.fillText(dataset.data[i], model.x, model.y - 5)
                }
              }
            })
          }
        }
      })
    }
  }
})
