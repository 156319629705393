
import { defineComponent, toRefs } from 'vue'
import { ElMessageBox } from '@/../node_modules/element-plus/es/components'

export default defineComponent({
  name: 'ChangelogComponent',
  inheritAttrs: false,

  setup () {
    return toRefs({})
  },

  mounted () {
    if (!this.$store.getters.isAdmin) {
      ElMessageBox.alert('Only administrators can access this page', 'Access Denied', {
        confirmButtonText: 'OK :-(',
        center: true,
        type: 'error',
        callback: () => {
          this.$router.replace('/generalSettings')
        }
      })
    }
  },

  methods: {
  }
})
