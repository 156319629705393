
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import _ from 'lodash'
import axios, { AxiosResponse } from 'axios'
import FiltersComponent from './partials/FiltersComponent.vue'
import { Team, Territory } from '@/interfaces/project'
import { Specialty } from '@/interfaces/specialty'
import { Segment } from '@/interfaces/segment'
import { CustomerGroup } from '@/interfaces/customergroup'

export default defineComponent({
  setup () {
    const store = useStore()

    const reactiveObj = reactive({
      activeNames: ['2', '3'],
      pageSize: 20,
      page: 1,
      tableOptions: false,
      loadingValues: false,

      sort: 'portfolioIndex',
      order: 'desc',

      filter: {
        segments: store.getters.segments.map((s) => s.id),
        salesTeams: store.getters.salesTeams.map((s) => s.id),
        territories: store.getters.territories.map((s) => s.id),
        specialties: store.getters.specialties.map((s) => s.id),
        customerGroups: store.getters.customerGroups.map((s) => s.id)
      },

      segments: []
    })

    return toRefs(reactiveObj)
  },

  components: {
    FiltersComponent
  },

  mounted: function () {
    this.filter.segments = this.$store.getters.segments.map((s) => s.id)
    this.filter.salesTeams = this.$store.getters.salesTeams.map((s) => s.id)
    this.filter.territories = this.$store.getters.territories.map((s) => s.id)
    this.filter.specialties = this.$store.getters.specialties.map((s) => s.id)
    this.filter.customerGroups = this.$store.getters.customerGroups.map((s) => s.id)
  },

  methods: {
    exportData: function (filtered: boolean) {
      this.$store.commit('SET_IS_LOADING', true)
      this.$store.commit('SET_LOADING_TEXT', 'Exporting data...')

      const data = {
        segments: this.filter.segments,
        teams: this.filter.salesTeams,
        territories: this.filter.territories,
        customerGroups: this.filter.customerGroups
      }

      const fileName = this.$store.getters.country.name +
        ' - ' +
        this.$store.getters.project.name +
        ' Portfolio Values' +
        ((filtered) ? ' - Filtered' : '') +
        '.xlsx'

      axios({
        url: 'projects/' + this.$store.getters.project.id + '/export-portfolio',
        method: 'get',
        responseType: 'blob',
        params: (filtered) ? data : {}
      }).then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        this.$store.commit('SET_IS_LOADING', false)
        this.$store.commit('SET_LOADING_TEXT', 'Loading data...')
      })
    },

    updateFilter: function (filter) {
      this.filter = filter
      this.page = 1
      this.loadingValues = true

      if (typeof this.$store.getters.project === 'undefined') {
        this.loadingValues = true
        return
      }

      const waitForUpdate = setInterval(() => {
        if (this.$store.getters.specialtiesWereUpdated === true) {
          this.$store.dispatch('LOAD_PORTFOLIO_VALUES_FILTERED', {
            projectId: this.$store.getters.project?.id,
            filter,
            page: parseInt('' + this.page),
            perPage: parseInt('' + this.pageSize),
            sort: this.sort,
            sortDirection: this.order
          }).then(() => {
            this.loadingValues = false
          })

          clearInterval(waitForUpdate)
        }
      }, 500)
    },

    resetFilter: function () {
      const filter = {
        salesTeams: this.$store.getters.salesTeams.map((s) => s.id),
        territories: this.$store.getters.territories.map((s) => s.id),
        segments: this.$store.getters.segments.map((s) => s.id),
        customerGroups: this.$store.getters.customerGroups.map((s) => s.id)
      }

      this.filter = filter
      this.updateFilter(filter)
    },

    getProductIndex: function (indexes, productId: string) {
      const productIndexes = _.find(indexes, (index) => index.id === 'productIndex_' + productId)

      if (typeof productIndexes === 'undefined') {
        return 0.0
      }

      return productIndexes.index
    },

    getSegmentName: function (seg: Segment): string {
      if (seg === null) {
        return 'N/A'
      }

      const segment = _.find(this.$store.getters.segments, (segment) => segment.id === seg.id)

      return (typeof segment?.name === 'undefined' || segment === null) ? 'N/A' : segment.name
    },

    getSegmentFrequency: function (seg: Segment): number {
      if (seg === null) {
        return 0
      }

      const segment = _.find(this.$store.getters.segments, (segment) => segment.id === seg.id)

      return (typeof segment?.callsYearly === 'undefined') ? 0 : segment.callsYearly
    },

    getCustomerGroupBySpecialty: function (specialty: Specialty) {
      if (specialty.customerGroup) {
        const customerGroup = _.find(this.$store.getters.customerGroups, (c) => {
          return c.id === specialty.customerGroup.split('/').pop()
        })

        return (typeof customerGroup !== 'undefined') ? customerGroup.name : 'N/A'
      }
    },

    reloadWithFilters: function (resetPage = false) {
      this.loadingValues = true

      this.$store.dispatch('LOAD_PORTFOLIO_VALUES_FILTERED', {
        projectId: this.$store.getters.project.id,
        filter: this.filter,
        page: (resetPage === true ? 1 : parseInt('' + this.page)),
        perPage: parseInt('' + this.pageSize),
        sort: this.sort,
        sortDirection: this.order
      }).then(() => {
        this.loadingValues = false
      })
    }
  },

  watch: {
    specialties: function (value) {
      this.filter.specialties = value.map((s) => s.id)
    },

    salesTeams: function (value) {
      this.filter.salesTeams = value.map((s) => s.id)
    },

    territories: function (value) {
      this.filter.territories = value.map((s) => s.id)
    },

    segments: function (value) {
      this.filter.segments = value.map((s) => s.id)
    },

    customerGroups: function (value) {
      this.filter.customerGroups = value.map((s) => s.id)
    },

    page: function () {
      this.reloadWithFilters()
    },

    pageSize: function () {
      this.reloadWithFilters(true)
    },

    sort: function () {
      this.reloadWithFilters(true)
    },

    order: function () {
      this.reloadWithFilters(true)
    }
  },

  computed: {
    specialties: function (): Array<Specialty> {
      return this.$store.getters.specialties
    },

    salesTeams: function (): Array<Team> {
      return this.$store.getters.salesTeams
    },

    territories: function (): Array<Territory> {
      return this.$store.getters.territories
    },

    segments: function (): Array<Segment> {
      return this.$store.getters.segments
    },

    customerGroups: function (): Array<CustomerGroup> {
      return this.$store.getters.customerGroups
    },

    totalMembers: function (): number {
      const count = 0
      /*
      this.$store.getters.portfolioValues['hydra:member'].forEach((m) => {

      }) */

      return count
    }
  }
})
