import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: "box-card",
    shadow: "never"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "form",
        "label-width": "120px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { gutter: 20 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                md: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  (_ctx.filter.bricks)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: "Bricks"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            placeholder: "Select bricks to filter",
                            multiple: "",
                            modelValue: _ctx.filter.bricks,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.bricks) = $event)),
                            "no-data-text": "There are no bricks yet",
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getBricksNames, (name) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: name.value,
                                  label: name.label,
                                  value: name.value
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.filter.segments)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 1,
                        label: "Segments"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            placeholder: "Select segments to filter",
                            multiple: "",
                            modelValue: _ctx.filter.segments,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.segments) = $event)),
                            "no-data-text": "There are no segments yet",
                            style: {"width":"100%"}
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSegmentsNames, (name) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: name.value,
                                  label: name.label,
                                  value: name.value
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                md: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { label: "Sales Team" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        placeholder: "Select sales teams to filter",
                        multiple: "",
                        "no-data-text": "There are no sales teams yet",
                        modelValue: _ctx.filter.salesTeams,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.salesTeams) = $event)),
                        style: {"width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSalesTeamNames, (name) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: name.value,
                              label: name.label,
                              value: name.value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { gutter: 20 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                md: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { label: "Territory" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        placeholder: "Select territories to filter",
                        multiple: "",
                        modelValue: _ctx.filter.territories,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.territories) = $event)),
                        "no-data-text": "There are no territories yet",
                        style: {"width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTerritoryNames, (name) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: name.value,
                              label: name.label,
                              value: name.value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                md: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { label: "Customer Group" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        placeholder: "Select customer groups to filter",
                        multiple: "",
                        "no-data-text": "There are no customer groups yet",
                        modelValue: _ctx.filter.customerGroups,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filter.customerGroups) = $event)),
                        style: {"width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCustomerGroupNames, (name) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: name.value,
                              label: name.label,
                              value: name.value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                md: 24,
                lg: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    class: "reset-filters-button",
                    label: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('reset'))),
                        type: "danger",
                        size: "mini"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Reset Filters")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}