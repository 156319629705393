import { Country } from '@/interfaces/country'
import { Project } from '@/interfaces/project'
import { Product } from '@/interfaces/product'
import { CustomerGroup } from '@/interfaces/customergroup'

import { RootState } from '@/store'
import { GetterTree } from 'vuex'

import { State } from './state'

export type Getters = {
  isLoading(state: State): boolean
  loadingText(state: State): string
  project(state: State): Project
  country(state: State): Country
  projects(state: State): Array<Project>
  countries(state: State): Array<Country>,
  products(state: State): Array<Product>,
  customerGroups(state: State): Array<CustomerGroup>
}

export const getters: GetterTree<State, RootState> & Getters = {
  isLoading: (state) => state.isLoading,
  loadingText: (state) => state.loadingText,
  project: (state) => state.project,
  country: (state) => state.country,
  projects: (state) => state.projects,
  countries: (state) => state.countries,
  products: (state) => state.products,
  customerGroups: (state) => state.customerGroups
}
