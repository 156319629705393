import { Country } from '@/interfaces/country'
import { Project } from '@/interfaces/project'
import { Product } from '@/interfaces/product'
import { CustomerGroup } from '@/interfaces/customergroup'

export type State = {
  isLoading: boolean
  loadingText: string
  project: Project | null
  country: Country | null
  projects: Array<Project>
  countries: Array<Country>
  products: Array<Product>
  customerGroups: Array<CustomerGroup>
}

export const state: State = {
  isLoading: false,
  loadingText: 'Loading data...',
  project: null,
  country: null,
  projects: [],
  countries: [],
  products: [],
  customerGroups: []
}
