
import { defineComponent, reactive, toRefs } from 'vue'
import { ElMessage } from '@/../node_modules/element-plus/es/components'
import { Specialty } from '@/interfaces/specialty'
import axios, { AxiosResponse } from 'axios'

export default defineComponent({
  inheritAttrs: false,

  setup () {
    const reactiveObj = reactive({
      activeNames: ['2'],
      deleteDialogVisibility: false,
      uploadDialogVisibility: false,
      fileList: [],
      loading: false
    })

    return toRefs(reactiveObj)
  },

  computed: {
    selectedProject: function () {
      return this.$store.getters.getSelectedProject
    },

    env: function () {
      return process.env
    },

    linkedSpecialtiesCount: function (): string {
      let count = 0

      this.$store.getters.specialties.forEach((s) => {
        if (s.customerGroup !== 'null') {
          count++
        }
      })

      return '' + count
    }
  },

  methods: {
    foundSpecialties: function (): string {
      return this.$store.getters.specialties.map((specialty: Specialty) => specialty.name).join(', ')
    },

    deleteActivityData: function () {
      ElMessage({
        message: 'Activity data deletion was queued',
        type: 'info'
      })

      this.deleteDialogVisibility = false

      this.$store.dispatch('DELETE_DATA', {
        projectId: this.$store.getters.project.id
      }).then(() => {
        ElMessage.success('Activity data were deleted')

        this.$store.dispatch('LOAD_SPECIALTIES', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_SALES_TEAMS', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_TERRITORIES', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project.id })
      })
    },

    uploadActivityData: function () {
      this.loading = true
      const formData = new FormData()

      if (typeof this.fileList === 'undefined' || this.fileList.length !== 1) {
        this.loading = false
        ElMessage.info('No files were selected for the upload')
        return
      }

      formData.append('file', this.fileList[0].raw)

      axios({
        url: 'projects/' + this.$store.getters.project.id + '/import-template',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }).then(() => {
        this.loading = false
        this.$refs.upload.clearFiles()
        ElMessage.success('Customer data uploaded successfully.')
        this.uploadDialogVisibility = false

        this.$store.commit('SET_IS_LOADING', true)

        Promise.all([
          this.$store.dispatch('LOAD_SPECIALTIES', { projectId: this.$store.getters.project.id }),
          this.$store.dispatch('LOAD_SALES_TEAMS', { projectId: this.$store.getters.project.id }),
          this.$store.dispatch('LOAD_TERRITORIES', { projectId: this.$store.getters.project.id }),
          this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project.id }),
          this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project.id }),
          this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project.id }),
          this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project.id })
        ]).finally(() => {
          this.$store.commit('SET_IS_LOADING', false)
        })
      }).catch((error) => {
        this.loading = false
        this.$refs.upload.clearFiles()
        ElMessage.error('Error has occured during upload.')

        this.uploadDialogVisibility = false
        console.log(error)
      })
    },

    exportTemplate: function () {
      this.$store.commit('SET_IS_LOADING', true)
      this.$store.commit('SET_LOADING_TEXT', 'Exporting template...')

      axios({
        url: 'projects/' + this.$store.getters.project.id + '/export-template',
        method: 'get',
        responseType: 'blob'
      }).then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.$store.getters.project.name + 'DataImportTemplate.xlsx')
        document.body.appendChild(link)
        link.click()
        this.$store.commit('SET_IS_LOADING', false)
        this.$store.commit('SET_LOADING_TEXT', 'Loading data...')
      })
    },

    updateSpecialty: function (): void {
      this.$store.commit('SET_SPECIALTIES_WERE_CHANGED', true)
      this.$store.commit('SET_SPECIALTIES_WERE_UPDATED', false)
    }
  }
})
