import { User } from '@/interfaces/user'
import { RootState } from '@/store'
import { GetterTree } from 'vuex'

import { State } from './state'

export type Getters = {
  users(state: State): Array<User>
}

export const getters: GetterTree<State, RootState> & Getters = {
  users: (state) => state.users
}
