export enum GeneralSettingsActionTypes {
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  REMOVE_CUSTOMER_GROUP = 'REMOVE_CUSTOMER_GROUP',
  ADD_PRODUCT = 'ADD_PRODUCT',
  ADD_CUSTOMER_GROUP = 'ADD_CUSTOMER_GROUP',
  UPDATE_PRODUCT_POSITION = 'UPDATE_PRODUCT_POSITION',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  UPDATE_CUSTOMER_GROUP = 'UPDATE_CUSTOMER_GROUP',
  UPDATE_PRODUCT_WEIGHTS = 'UPDATE_PRODUCT_WEIGHTS',
  UPDATE_RESPONSIBILITIES = 'UPDATE_RESPONSIBILITIES',
  ADD_PROJECT = 'ADD_PROJECT'
}
