export type CountryId = {
  countryId: string
}

export type WithInactive = {
  withInactive: boolean
}

export type ProjectId = {
  projectId: string
}

export type FilteredProjectId = {
  projectId: string,
  filter: {
    segments: Array<string>,
    salesTeams: Array<string>,
    territories: Array<string>,
    customerGroups: Array<string>
  },
  page: number,
  perPage: number,
  sort: string,
  sortDirection: string
}

export type OnlyId = {
  id: string
}

export type NewProduct = {
  name: string,
  notLaunched: boolean,
  projectId: string
}

export type Product = {
  name: string,
  notLaunched: boolean,
  productId: string
}

export type CustomerGroup = {
  name: string,
  customerGroupId: string
}

export type NewCustomerGroup = {
  name: string,
  projectId: string
}

export type ProductPosition = {
  projectId: string,
  productId: null | string,
  customerGroupId: string,
  position: number
}

export type ProjectProductId = {
  projectId: string,
  productId: Array<string>
}

export type Login = {
  username: string,
  password: string
}

export type ProductWeight = {
  projectId: string,
  productCount: number,
  position: number,
  weight: number
}

export type Responsibility = {
  name: string,
  email: string,
  phone: string
}

export type Responsibilities = {
  projectId: string,
  projectLeader: Responsibility | undefined,
  regionalSupport:Responsibility | undefined,
  localSupport: Responsibility | undefined
}

export type NewFactor = {
  name: string,
  weight: string,
  productId: string
}

export type Factor = {
  id: string,
  name: string,
  weight: string|number
}

export enum SegmentType {
  SOMETHING = 'Something',
  GAIN = 'Gain',
  MAINTAIN = 'Maintain',
  IGNORE = 'Ignore',
  NOT_TARGETED = 'Not Targeted',
}

export type ProjectFteCalc = {
  projectId: string,
  daysInField: string,
  callsDaily: string,
  callsYearly: string
}

export type NewProject = {
  name: string,
  country: string
}
