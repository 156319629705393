import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Sentry from '@sentry/vue'

import { store as global, GlobalStore, State as GlobalState } from './modules/global/index'
import { store as auth, AuthStore, State as AuthState } from './modules/auth/index'
import { store as generalSettings, GeneralSettingsStore, State as GeneralSettingsState } from './modules/generalSettings/index'
import { store as segmentationTargeting, SegmentationTargetingStore, State as SegmentationTargetingState } from './modules/segmentationTargeting/index'
import { store as dataInput, DataInputStore, State as DataInputState } from './modules/dataInput/index'
import { store as user, UserStore, State as UserState } from './modules/users/index'

export type RootState = {
  global: GlobalState,
  auth: AuthState,
  generalSettings: GeneralSettingsState,
  segmentationTargeting: SegmentationTargetingState,
  dataInput: DataInputState,
  user: UserState
}

export type JoinedState = GlobalState
  & AuthState
  & GeneralSettingsState
  & SegmentationTargetingState
  & DataInputState
  & UserState

export type Store = AuthStore<Pick<RootState, 'auth'>>
 & GeneralSettingsStore<Pick<RootState, 'generalSettings'>>
 & SegmentationTargetingStore<Pick<RootState, 'segmentationTargeting'>>
 & GlobalStore<Pick<RootState, 'global'>>
 & DataInputStore<Pick<RootState, 'dataInput'>>
 & UserStore<Pick<RootState, 'user'>>

const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

plugins.push(createPersistedState({
  storage: window.sessionStorage,
  paths: [
    'auth'
  ]
}))

plugins.push((store) => {
  store.subscribeAction((action) => {
    Sentry.configureScope(scope => scope.setTransactionName(action.type))
  })
})

export const store = createStore({
  plugins,
  modules: {
    global,
    auth,
    generalSettings,
    segmentationTargeting,
    dataInput,
    user
  }
})

export function useStore (): Store {
  return store as Store
}
