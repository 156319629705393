import { ActionContext, ActionTree } from 'vuex'

import { State } from './state'
import { Mutations } from './mutations'
import { AuthMutationTypes } from './mutation-types'
import { AuthActionTypes } from './action-types'
import axios, { AxiosError, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { RootState } from '@/store'
import { Login } from '@/interfaces/auth'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [AuthActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    data: Login
  ): Promise<boolean>
  [AuthActionTypes.LOGOUT](
    { commit }: AugmentedActionContext,
  ): Promise<boolean>
  [AuthActionTypes.CHECK_JWT](
    { commit }: AugmentedActionContext,
  ): Promise<boolean>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [AuthActionTypes.LOGIN] ({ commit }, data: Login) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: 'authentication/sign-in',
        data
      }).then((res: AxiosResponse) => {
        Cookies.set('jwt-token', res.data.token, {
          expires: 1
        })

        const interval = setInterval(() => {
          if (typeof Cookies.get('jwt-token') !== 'undefined') {
            axios.defaults.headers.common.Authorization = 'Bearer ' + Cookies.get('jwt-token')
            clearInterval(interval)
            commit(AuthMutationTypes.SET_LOGGED_IN, true)

            axios({
              method: 'get',
              url: 'me'
            }).then((res: AxiosResponse) => {
              commit(AuthMutationTypes.SET_USER, res.data)
            })

            resolve(true)
          }
        })
      }).catch((error: AxiosError) => {
        commit(AuthMutationTypes.SET_LOGGED_IN, false)
        reject(error.message)
      })
    })
  },

  async [AuthActionTypes.LOGOUT] ({ commit }) {
    return new Promise((resolve) => {
      Cookies.remove('jwt-token')
      commit(AuthMutationTypes.SET_LOGGED_IN, false)
      commit(AuthMutationTypes.SET_USER, null)
      resolve(true)
    })
  },

  async [AuthActionTypes.CHECK_JWT] ({ commit }) {
    return new Promise((resolve, reject) => {
      if (typeof Cookies.get('jwt-token') === 'undefined') {
        commit(AuthMutationTypes.SET_LOGGED_IN, false)

        reject(new Error('Token is not valid'))
      }

      axios.defaults.headers.common.Authorization = 'Bearer ' + Cookies.get('jwt-token')

      axios({
        method: 'get',
        url: 'me'
      }).then((res: AxiosResponse) => {
        commit(AuthMutationTypes.SET_USER, res.data)
        commit(AuthMutationTypes.SET_LOGGED_IN, true)

        resolve(true)
      }).catch((error: AxiosError) => {
        commit(AuthMutationTypes.SET_LOGGED_IN, false)
        reject(error.message)
      })
    })
  }
}
