
import { defineComponent, reactive, toRefs } from 'vue'
import { Brick, Team, Territory } from '@/interfaces/project'
import { Specialty } from '@/interfaces/specialty'
import { CustomerGroup } from '@/interfaces/customergroup'
import { Segment } from '@/interfaces/segment'

export default defineComponent({
  name: 'FiltersComponent',

  setup (props) {
    const reactiveObj = reactive({
      activeNames: ['2', '3'],
      filter: props.filters
    })

    return toRefs(reactiveObj)
  },

  props: {
    filters: Object
  },

  watch: {
    filter: {
      handler: function (value) {
        this.$emit('update', value)
      },

      deep: true
    },

    filters: {
      handler: function (value) {
        this.filter = value
      },

      deep: true
    }
  },

  computed: {
    getBricksNames: function () {
      const bricks = this.$store.getters.bricks

      return bricks
        ? bricks.map((s: Brick) => {
          return { label: s.name, value: s.id }
        })
        : []
    },

    getSegmentsNames: function () {
      const segments = this.$store.getters.segments

      return segments
        ? segments.map((s: Segment) => {
          return { label: s.name, value: s.id }
        })
        : []
    },

    getSalesTeamNames: function () {
      const salesTeams = this.$store.getters.salesTeams

      return salesTeams
        ? salesTeams.map((s: Team) => {
          return { label: s.name, value: s.id }
        })
        : []
    },

    getTerritoryNames: function () {
      const territories = this.$store.getters.territories

      return territories
        ? territories.map((s: Territory) => {
          return { label: s.name, value: s.id }
        })
        : []
    },

    getSpecialtyNames: function () {
      const specialties = this.$store.getters.specialties

      return specialties
        ? specialties.map((s: Specialty) => {
          return { label: s.name, value: s.id }
        })
        : []
    },

    getCustomerGroupNames: function () {
      const customerGroups = this.$store.getters.customerGroups

      return customerGroups
        ? customerGroups.map((s: CustomerGroup) => {
          return { label: s.name, value: s.id }
        })
        : []
    }
  }
})
