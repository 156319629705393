import { MutationTree } from 'vuex'
import { GeneralSettingsMutationTypes } from './mutation-types'
import { State } from './state'
import _ from 'lodash'
import { ProductPosition, Project } from '@/interfaces/project'
import { CustomerGroup } from '@/interfaces/customergroup'

function createEmptyPositions (customerGroupId: string) {
  const gr = []

  for (let i = 0; i < 5; i++) {
    if (typeof gr[i] === 'undefined') {
      gr[i] = {
        id: 'null',
        customerGroup: { id: customerGroupId },
        product: { id: 'null' },
        position: i + 1
      }
    }
  }

  return gr
}

export type Mutations<S = State> = {
  [GeneralSettingsMutationTypes.SET_PRODUCT_POSITIONS] (state: S, project: Project): void
}

export const mutations: MutationTree<State> & Mutations = {
  [GeneralSettingsMutationTypes.SET_PRODUCT_POSITIONS] (state: State, project: Project) {
    const positions: Array<ProductPosition> = []

    if (typeof project === 'undefined') {
      state.detailingPositions = {}
    }

    project.productPositions.forEach((element: ProductPosition) => {
      positions.push({
        id: element.id,
        customerGroup: { id: element.customerGroup.id },
        product: { id: element.product.id },
        position: element.position
      })
    })

    const grouped = _.groupBy(positions, (o: ProductPosition) => o.customerGroup.id)
    const customerGroups = this.getters.customerGroups.map((cg: CustomerGroup) => cg.id)

    for (let c = 0; c < customerGroups.length; c++) {
      if (typeof grouped[customerGroups[c]] === 'undefined') {
        grouped[customerGroups[c]] = createEmptyPositions(customerGroups[c])
      }
    }

    state.detailingPositions = _.mapValues(grouped, function (gr) {
      const customerGroupId = gr[0].customerGroup.id
      const keyed = _.keyBy(gr, 'position')

      for (let i = 1; i <= 5; i++) {
        if (typeof keyed[i] === 'undefined') {
          const position: ProductPosition = {
            id: null,
            customerGroup: { id: customerGroupId },
            product: { id: 'null' },
            position: i + 1
          }

          keyed[i] = position
        }
      }

      return keyed
    })
  }
}
