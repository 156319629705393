export enum GlobalMutationTypes {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_LOADING_TEXT = 'SET_LOADING_TEXT',
  SET_COUNTRIES = 'SET_COUNTRIES',
  SET_PROJECTS = 'SET_PROJECTS',
  SET_COUNTRY = 'SET_COUNTRY',
  SET_PROJECT = 'SET_PROJECT',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_CUSTOMER_GROUPS = 'SET_CUSTOMER_GROUPS'
}
