import { createApp } from 'vue'
import '@/../node_modules/element-plus/theme-chalk/index.css'
import '@/../node_modules/element-plus/theme-chalk/display.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import App from './App.vue'
import { ElMessage } from '@/../node_modules/element-plus/es/components'
import { store } from './store'
import router from './router'
import axios, { AxiosError, AxiosResponse } from 'axios'
import VueAxios from 'vue-axios'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { AuthActionTypes } from './store/modules/auth/action-types'
const app = createApp(App)

app.use(ElementPlus, { size: 'large', zIndex: 3000 })
app.use(router)
app.use(store)
app.use(VueAxios, axios)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Authorization = 'Bearer ' + Cookies.get('jwt-token')
axios.defaults.headers.common.Accept = 'application/ld+json'

const isDev = process.env.NODE_ENV !== 'production'
app.config.performance = isDev
app.config.globalProperties.$version = 'v2.3.1'

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  environment: process.env.NODE_ENV,
  ignoreErrors: [
    'CHECK_JWT',
    'Token is not valid'
  ],
  beforeSend (event, hint) {
    if (process.env.VUE_APP_SENTRY_ENABLE_FEEDBACK === 'false') {
      return event
    }

    // eslint-disable-next-line
    const originalException = (hint.originalException as any)

    if (event.environment === 'development' ||
      originalException.response.status === 422 ||
      originalException.response.status === 401 ||
      !event.exception
    ) {
      return event
    }

    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: {
          email: store.getters.user.email,
          name: store.getters.user.fullName
        }
      })
    }
    return event
  },
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'worwag.dev.grownapps.io', 'localhost:8080', /^\//]
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_SAMPLE_RATE),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

axios.interceptors.response.use(function (response: AxiosResponse) {
  return response
}, function (error: AxiosError) {
  // eslint-disable-next-line
  const data: any = error.response?.data

  const violations = data?.violations || []

  if (typeof error.response === 'undefined') {
    return
  }

  const headers = error.response.headers

  Sentry.configureScope(scope => {
    scope.setTags({
      project: (localStorage) ? localStorage.selectedProjectId : null,
      country: (localStorage) ? localStorage.selectedCountryId : null
    })

    scope.setExtras({
      profiler: (typeof headers['X-Debug-Token-Link'] !== 'undefined') ? headers['X-Debug-Token-Link'] : null
    })
  })

  // HTTP 500 SERVER ERROR
  if (error.response.status === 500) {
    ElMessage.error('Server error has occured. Please try again.')
  }

  // OTHER ERRORS ( USER OR REQUEST )
  if (error.response.status !== 500 && error.response.status !== 401) {
    if (violations.length > 0) {
      let violationsText = ''

      violations.forEach((violation: {code: string, message: string, propertyType: string}) => {
        violationsText += '<p style="line-height: 1.6">' + violation.message + '</p>'
      })

      ElMessage({
        type: 'error',
        dangerouslyUseHTMLString: true,
        message: violationsText
      })
    } else {
      ElMessage.error(error.message)
    }
  }

  // HTTP 401 UNAUTHORIZED
  if (error.response.status === 401) {
    store.dispatch(AuthActionTypes.LOGOUT)
  }

  return Promise.reject(error)
})

app.mount('#app')
