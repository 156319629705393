import { Country } from '@/interfaces/country'
import { Project } from '@/interfaces/project'
import { Product } from '@/interfaces/product'
import { CustomerGroup } from '@/interfaces/customergroup'

import { ActionContext, ActionTree } from 'vuex'
import { State } from './state'
import { Mutations } from './mutations'
import { GlobalActionTypes } from './action-types'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { RootState } from '@/store'
import { CountryId, ProjectId, ProjectFteCalc, WithInactive } from '../@types/entity'
import { GlobalMutationTypes } from './mutation-types'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [GlobalActionTypes.LOAD_COUNTRIES](
    { commit }: AugmentedActionContext,
    data: WithInactive
  ): Promise<Array<Country>>
  [GlobalActionTypes.LOAD_PROJECTS](
    { commit }: AugmentedActionContext,
    data: CountryId
  ): Promise<Array<Project>>
  [GlobalActionTypes.LOAD_PROJECT_INFO](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<Country>
  [GlobalActionTypes.LOAD_COUNTRY_INFO](
    { commit }: AugmentedActionContext,
    data: CountryId
  ): Promise<Project>
  [GlobalActionTypes.LOAD_PRODUCTS](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<Array<Product>>
  [GlobalActionTypes.LOAD_CUSTOMER_GROUPS](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<Array<CustomerGroup>>
  [GlobalActionTypes.UPDATE_PROJECT](
    { commit }: AugmentedActionContext,
    data: ProjectFteCalc
  ): Promise<Project>
}

/* eslint-disable @typescript-eslint/no-unused-vars */

export const actions: ActionTree<State, RootState> & Actions = {
  async [GlobalActionTypes.LOAD_COUNTRIES] ({ commit }, data: WithInactive) {
    const res: AxiosResponse = await axios({
      url: 'countries',
      method: 'get'
    })

    const countries: Array<Country> = []

    res.data['hydra:member'].forEach((element: Country) => {
      if (data.withInactive || element.active) {
        countries.push({
          id: element.id,
          alpha2Code: element.alpha2Code,
          name: element.name
        })
      }
    })

    countries.sort(function (a: Country, b: Country) {
      return ('' + a.name).localeCompare(b.name)
    })

    if (!data.withInactive) {
      commit(GlobalMutationTypes.SET_COUNTRIES, countries)
    }

    return countries
  },

  async [GlobalActionTypes.LOAD_PROJECTS] ({ commit }, data: CountryId) {
    const res: AxiosResponse = await axios({
      url: 'projects?country=' + data.countryId,
      method: 'get'
    })

    const projects: Array<Project> = []

    res.data['hydra:member'].forEach((element: Project) => {
      projects.push(element)
    })

    projects.sort(function (a: Country, b: Country) {
      return ('' + a.name).localeCompare(b.name)
    })

    commit(GlobalMutationTypes.SET_PROJECTS, projects)

    return projects
  },

  async [GlobalActionTypes.LOAD_PROJECT_INFO] ({ commit }, data: ProjectId) {
    const res: AxiosResponse = await axios({
      url: 'projects/' + data.projectId,
      method: 'get'
    })

    commit(GlobalMutationTypes.SET_PROJECT, res.data)

    return res.data
  },

  async [GlobalActionTypes.LOAD_COUNTRY_INFO] ({ commit }, data: CountryId) {
    const res: AxiosResponse = await axios({
      url: 'countries/' + data.countryId,
      method: 'get'
    })

    commit(GlobalMutationTypes.SET_COUNTRY, res.data)

    return res.data
  },

  async [GlobalActionTypes.LOAD_PRODUCTS] ({ commit }, data: ProjectId) {
    const res: AxiosResponse = await axios({
      url: 'products?project=' + data.projectId,
      method: 'get'
    })

    const products: Array<Product> = []

    res.data['hydra:member'].forEach((element: Product) => {
      products.push({
        '@id': element['@id'],
        name: element.name,
        launched: !element.launched,
        id: element.id
      })
    })

    commit(GlobalMutationTypes.SET_PRODUCTS, products)

    return products
  },

  async [GlobalActionTypes.LOAD_CUSTOMER_GROUPS] ({ commit }, data: ProjectId) {
    const res: AxiosResponse = await axios({
      url: 'customer-groups?project=' + data.projectId,
      method: 'get'
    })

    const customerGroups: Array<CustomerGroup> = []

    res.data['hydra:member'].forEach((element: CustomerGroup) => {
      customerGroups.push({
        id: element.id,
        project: data.projectId,
        name: element.name
      })
    })

    commit(GlobalMutationTypes.SET_CUSTOMER_GROUPS, customerGroups)

    return customerGroups
  },

  async [GlobalActionTypes.UPDATE_PROJECT] ({ commit }, data: ProjectFteCalc) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'projects/' + data.projectId,
        method: 'put',
        data: {
          callsDaily: parseFloat('' + data.callsDaily),
          daysInField: parseFloat('' + data.daysInField)
        }
      }).then((res: AxiosResponse) => {
        commit(GlobalMutationTypes.SET_PROJECT, res.data)
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  }
}
