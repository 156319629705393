
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'AuthComponent',

  setup () {
    return {
      model: ref({
        username: '',
        password: '',
        errorVisibility: false,
        errorMessage: 'Username or password is incorrect'
      }),

      rules: {
        username: [
          {
            required: true,
            message: '',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '',
            trigger: 'blur'
          }
        ]
      }
    }
  },

  methods: {
    login: async function () {
      const valid = await this.$refs.form.validate()
      this.model.errorVisibility = false

      if (!valid) {
        this.model.errorMessage = 'Username and password must not be empty'
        this.model.errorVisibility = true
        return
      }

      this.$store.dispatch('LOGIN', {
        username: this.model.username,
        password: this.model.password
      }).then(() => {
        this.model.errorVisibility = false
      }).catch((error) => {
        if (error === 'Request failed with status code 401') {
          this.model.errorVisibility = true
        }
      })
    }
  }
})
