import { MutationTree } from 'vuex'
import { State } from './state'
import _ from 'lodash'

import { DataInputMutationTypes } from './mutation-types'
import { Segment } from '@/interfaces/segment'
import { Brick, PortfolioValue, Team, Territory, TerritoryFte } from '@/interfaces/project'
import { Specialty } from '@/interfaces/specialty'

export type Mutations<S = State> = {
  [DataInputMutationTypes.SET_SPECIALTIES](state: S, specialties: Array<Specialty>): void
  [DataInputMutationTypes.SET_TERRITORIES](state: S, territories: Array<Territory>): void
  [DataInputMutationTypes.SET_SALES_TEAMS](state: S, salesTeams: Array<Team>): void
  [DataInputMutationTypes.SET_BRICKS](state: S, bricks: Array<Brick>): void
  [DataInputMutationTypes.SET_PORTFOLIO_VALUES](state: S, values: Array<PortfolioValue>): void
  [DataInputMutationTypes.SET_TERRITORY_FTES](state: S, values: Array<TerritoryFte>): void
  [DataInputMutationTypes.SET_SEGMENTS](state: S, segments: Array<Segment>): void
  [DataInputMutationTypes.SET_SPECIALTIES_WERE_CHANGED](state: S, value: boolean): void
  [DataInputMutationTypes.SET_SPECIALTIES_WERE_UPDATED](state: S, value: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [DataInputMutationTypes.SET_SPECIALTIES] (state: State, specialties: Array<Specialty>) {
    if (typeof specialties['hydra:member'] !== 'undefined') {
      specialties = specialties['hydra:member'].map((specialty) => {
        specialty.customerGroup = (specialty.customerGroup) ? specialty.customerGroup.split('/').pop() : 'null'
        return specialty
      })
    }

    state.specialties = specialties
  },

  [DataInputMutationTypes.SET_TERRITORIES] (state: State, territories: Array<Territory>) {
    if (typeof territories['hydra:member'] !== 'undefined') {
      territories = territories['hydra:member'].map((value) => {
        value.name = (value.name === '') ? 'N/A' : value.name

        return value
      })
    }

    state.territories = territories
  },

  [DataInputMutationTypes.SET_SALES_TEAMS] (state: State, salesTeams: Array<Team>) {
    if (typeof salesTeams['hydra:member'] !== 'undefined') {
      salesTeams = salesTeams['hydra:member'].map((value: Team) => {
        value.name = (value.name === '') ? 'N/A' : value.name

        return value
      })
    }

    state.salesTeams = salesTeams
  },

  [DataInputMutationTypes.SET_BRICKS] (state: State, bricks: Array<Brick>) {
    state.bricks = bricks
  },

  [DataInputMutationTypes.SET_PORTFOLIO_VALUES] (state: State, values: Array<PortfolioValue>) {
    state.portfolioValues = values
  },

  [DataInputMutationTypes.SET_TERRITORY_FTES] (state: State, values: Array<TerritoryFte>) {
    let territoryFtes = []

    if (typeof values['hydra:member'] !== 'undefined') {
      territoryFtes = values['hydra:member'].map((tf) => {
        const tSingle = _.find(state.territories, (t: Territory) => t.id === tf.id)

        tf.territory = tSingle ? tSingle.name : 'N/A'

        return tf
      })
    }

    state.territoryFtes = territoryFtes
  },

  [DataInputMutationTypes.SET_SEGMENTS] (state: State, segments: Array<Segment>) {
    state.segments = (typeof segments !== 'undefined') ? segments['hydra:member'] : []
  },

  [DataInputMutationTypes.SET_SPECIALTIES_WERE_CHANGED] (state: State, value: boolean) {
    state.specialtiesWereChanged = value
  },

  [DataInputMutationTypes.SET_SPECIALTIES_WERE_UPDATED] (state: State, value: boolean) {
    state.specialtiesWereUpdated = value
  }
}
