import { ProductPosition, Responsibility } from '@/interfaces/project'
import { RootState, JoinedState } from '@/store'
import { GetterTree } from 'vuex'

import { State } from './state'

export type Getters = {
  responsibilities(state: State, rootState: JoinedState): Array<Responsibility>
  detailingPositions(state: State): { [x: string]: ProductPosition }
}

export const getters: GetterTree<State, RootState> & Getters = {
  responsibilities: (state, rootState: JoinedState) => {
    const r = ['projectLeader', 'regionalSupport', 'localSupport']
    const roles: Array<Responsibility> = []

    if (rootState.project === null) {
      return []
    }

    r.forEach((role) => {
      const roleName = role
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, function (str) { return str.toUpperCase() })

      roles.push({
        role: roleName,
        firstName: rootState.project[role].firstName,
        lastName: rootState.project[role].lastName,
        email: rootState.project[role].email
      })
    })

    return roles
  },

  detailingPositions: (state) => state.detailingPositions
}
