import { ActionContext, ActionTree } from 'vuex'
import { State } from './state'
import { Mutations } from './mutations'
import { DataInputMutationTypes } from './mutation-types'
import { DataInputActionTypes } from './action-types'
import axios, { AxiosResponse } from 'axios'
import { RootState } from '@/store'
import { OnlyId, ProjectId, FilteredProjectId } from '../@types/entity'
import _ from 'lodash'
import { Specialty } from '@/interfaces/specialty'
import { Segment } from '@/interfaces/segment'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [DataInputActionTypes.LOAD_SPECIALTIES](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.UPDATE_SPECIALTY](
    { commit }: AugmentedActionContext,
    data: Specialty
  ): Promise<AxiosResponse>,
  [DataInputActionTypes.LOAD_SALES_TEAMS](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.LOAD_TERRITORIES](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.LOAD_BRICKS](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.LOAD_PORTFOLIO_VALUES](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.LOAD_PORTFOLIO_VALUES_FILTERED](
    { commit }: AugmentedActionContext,
    data: FilteredProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.LOAD_TERRITORY_FTES](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.LOAD_TERRITORY_FTES_FILTERED](
    { commit }: AugmentedActionContext,
    data: FilteredProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.LOAD_SEGMENTS](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.LOAD_SEGMENTS_FILTERED](
    { commit }: AugmentedActionContext,
    data: FilteredProjectId
  ): Promise<boolean>,
  [DataInputActionTypes.UPDATE_SEGMENT](
    { commit }: AugmentedActionContext,
    data: Segment
  ): Promise<AxiosResponse>,
  [DataInputActionTypes.DELETE_DATA](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<AxiosResponse>,
  [DataInputActionTypes.UPDATE_SPECIALTIES](
    { commit }: AugmentedActionContext,
    data: ProjectId
  ): Promise<boolean>
}

/* eslint-disable @typescript-eslint/no-unused-vars */

export const actions: ActionTree<State, RootState> & Actions = {
  async [DataInputActionTypes.LOAD_SPECIALTIES] ({ commit }, data: ProjectId) {
    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'specialties?project=' + data.projectId
    })

    commit(DataInputMutationTypes.SET_SPECIALTIES, promise.data)

    return promise.data
  },

  async [DataInputActionTypes.LOAD_SALES_TEAMS] ({ commit }, data: ProjectId) {
    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'projects/' + data.projectId + '/sales-teams'
    })

    commit(DataInputMutationTypes.SET_SALES_TEAMS, promise.data)

    return promise.data
  },

  async [DataInputActionTypes.LOAD_TERRITORIES] ({ commit }, data: ProjectId) {
    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'projects/' + data.projectId + '/territories'
    })

    commit(DataInputMutationTypes.SET_TERRITORIES, promise.data)

    return promise.data
  },

  async [DataInputActionTypes.LOAD_BRICKS] ({ commit }, data: ProjectId) {
    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'projects/' + data.projectId + '/bricks'
    })

    commit(DataInputMutationTypes.SET_BRICKS, promise.data)

    return promise.data
  },

  async [DataInputActionTypes.LOAD_PORTFOLIO_VALUES] ({ commit }, data: ProjectId) {
    if (typeof data.projectId === 'undefined') {
      return false
    }

    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'projects/' + data.projectId + '/portfolio-values'
    })

    if (typeof promise !== 'undefined' && typeof promise.data !== 'undefined' && promise.data) {
      commit(DataInputMutationTypes.SET_PORTFOLIO_VALUES, promise.data)
    } else {
      commit(DataInputMutationTypes.SET_PORTFOLIO_VALUES, [])
    }

    return promise.data
  },

  async [DataInputActionTypes.LOAD_PORTFOLIO_VALUES_FILTERED] ({ commit }, data: FilteredProjectId) {
    if (typeof data.projectId === 'undefined') {
      return false
    }

    const teams = data.filter.salesTeams.map((team) => team.split('-').pop())
    const territories = data.filter.territories.map((territory) => territory.split('-').pop())

    const order = {}
    order[data.sort] = data.sortDirection

    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'projects/' + data.projectId + '/portfolio-values',
      params: {
        segments: data.filter.segments,
        teams,
        territories,
        customerGroups: data.filter.customerGroups,
        page: data.page,
        itemsPerPage: data.perPage,
        order
      }
    })

    if (typeof promise !== 'undefined' && typeof promise.data !== 'undefined' && promise.data) {
      commit(DataInputMutationTypes.SET_PORTFOLIO_VALUES, promise.data)
    } else {
      commit(DataInputMutationTypes.SET_PORTFOLIO_VALUES, [])
    }

    return promise.data
  },

  async [DataInputActionTypes.LOAD_TERRITORY_FTES] ({ commit }, data: ProjectId) {
    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'projects/' + data.projectId + '/territories-ftes'
    })

    commit(DataInputMutationTypes.SET_TERRITORY_FTES, promise.data)

    return promise.data
  },

  async [DataInputActionTypes.LOAD_TERRITORY_FTES_FILTERED] ({ commit }, data: FilteredProjectId) {
    const teams = data.filter.salesTeams.map((team) => team.split('-').pop())
    const territories = data.filter.territories.map((territory) => territory.split('-').pop())

    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'projects/' + data.projectId + '/territories-ftes',
      params: {
        segments: data.filter.segments,
        teams,
        territories,
        customerGroups: data.filter.customerGroups
      }
    })

    commit(DataInputMutationTypes.SET_TERRITORY_FTES, promise.data)

    return promise.data
  },

  async [DataInputActionTypes.UPDATE_SPECIALTY] ({ commit }, data: Specialty) {
    return axios({
      method: 'put',
      url: 'specialties/' + data.id,
      data: {
        customerGroup: (data.customerGroup === 'null') ? null : '/api/v1/customer-groups/' + data.customerGroup
      }
    })
  },

  async [DataInputActionTypes.LOAD_SEGMENTS] ({ commit }, data: ProjectId) {
    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'segments?project=' + data.projectId
    })

    commit(DataInputMutationTypes.SET_SEGMENTS, promise.data)

    return promise.data
  },

  async [DataInputActionTypes.LOAD_SEGMENTS_FILTERED] ({ commit }, data: FilteredProjectId) {
    const teams = data.filter.salesTeams.map((team) => team.split('-').pop())
    const territories = data.filter.territories.map((territory) => territory.split('-').pop())

    const promise: AxiosResponse = await axios({
      method: 'get',
      url: 'projects/' + data.projectId + '/segment-stats/',
      params: {
        segments: data.filter.segments,
        teams,
        territories,
        customerGroups: data.filter.customerGroups
      }
    })

    return promise.data
  },

  async [DataInputActionTypes.UPDATE_SEGMENT] ({ commit }, data: Segment) {
    return axios({
      method: 'put',
      url: 'segments/' + data.id,
      data: {
        name: data.name,
        max: parseFloat('' + data.max),
        min: parseFloat('' + data.min),
        targetedCustomers: parseInt('' + data.targetedCustomers),
        callsYearly: parseInt('' + data.callsYearly),
        project: data.project
      }
    })
  },

  async [DataInputActionTypes.ADD_SEGMENT] ({ commit }, data: Segment) {
    return axios({
      method: 'post',
      url: 'segments',
      data: {
        type: _.snakeCase(data.type),
        name: data.name,
        max: parseFloat('' + data.max),
        min: parseFloat('' + data.min),
        targetedCustomers: parseInt('' + data.targetedCustomers),
        callsYearly: parseInt('' + data.callsYearly),
        project: data.project
      }
    })
  },

  async [DataInputActionTypes.DELETE_SEGMENT] ({ commit }, data: OnlyId) {
    return axios({
      method: 'delete',
      url: 'segments/' + data.id
    })
  },

  async [DataInputActionTypes.DELETE_DATA] ({ commit }, data: ProjectId) {
    return axios({
      method: 'delete',
      url: 'projects/' + data.projectId + '/import-data'
    })
  },

  async [DataInputActionTypes.UPDATE_SPECIALTIES] ({ commit, getters }, data: ProjectId) {
    if (getters.specialtiesWereChanged !== true) {
      return false
    }

    const send = []

    getters.specialties.forEach((specialty: Specialty) => {
      send.push({
        specialty: specialty['@id'],
        customerGroup: (specialty.customerGroup === 'null') ? null : process.env.VUE_APP_IRI_PREFIX + 'customer-groups/' + specialty.customerGroup
      })
    })

    commit(DataInputMutationTypes.SET_SPECIALTIES_WERE_CHANGED, false)

    axios({
      method: 'put',
      url: 'projects/' + data.projectId + '/mass-link',
      data: {
        specialties: send
      }
    }).then(() => {
      commit(DataInputMutationTypes.SET_SPECIALTIES_WERE_UPDATED, true)
    })

    return true
  }
}
