import { ActionContext, ActionTree } from 'vuex'

import { State } from './state'
import { Mutations } from './mutations'
import { SegmentationTargetingActionTypes } from './action-types'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { RootState } from '@/store'

import { OnlyId, NewFactor, Factor, ProjectId } from '../@types/entity'
import { SegmentationTargetingMutationTypes } from './mutation-types'
import { SegmentationFactor } from '@/interfaces/segmentationfactor'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [SegmentationTargetingActionTypes.LOAD_FACTORS](
    { commit }: AugmentedActionContext,
    data: null
  ): Promise<Array<SegmentationFactor>>,
  [SegmentationTargetingActionTypes.ADD_FACTOR](
    { commit }: AugmentedActionContext,
    data: NewFactor
  ): Promise<void | SegmentationFactor>,
  [SegmentationTargetingActionTypes.UPDATE_FACTOR](
    { commit }: AugmentedActionContext,
    data: null
    ): Promise<boolean>,
  [SegmentationTargetingActionTypes.REMOVE_FACTOR](
    { commit }: AugmentedActionContext,
    data: OnlyId
    ): Promise<boolean>
}

/* eslint-disable @typescript-eslint/no-unused-vars */

export const actions: ActionTree<State, RootState> & Actions = {
  async [SegmentationTargetingActionTypes.LOAD_FACTORS] ({ commit }, data: ProjectId) {
    const promise = await axios({
      url: 'segmentation-factors?product.project=' + data.projectId,
      method: 'get'
    })

    if (typeof promise === 'undefined') {
      return []
    }

    const factors: Array<SegmentationFactor> = []

    promise.data['hydra:member'].forEach((element: SegmentationFactor) => {
      factors.push(element)
    })

    commit(SegmentationTargetingMutationTypes.SET_FACTORS, factors)

    return factors
  },

  async [SegmentationTargetingActionTypes.ADD_FACTOR] ({ commit }, data: NewFactor) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'segmentation-factors',
        method: 'post',
        data: {
          name: data.name,
          weight: parseInt('' + data.weight),
          product: process.env.VUE_APP_IRI_PREFIX + 'products/' + data.productId
        }
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [SegmentationTargetingActionTypes.UPDATE_FACTOR] ({ commit }, data: Factor) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'segmentation-factors/' + data.id,
        method: 'put',
        data: {
          name: data.name,
          weight: parseInt('' + data.weight)
        }
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [SegmentationTargetingActionTypes.REMOVE_FACTOR] ({ commit }, data: OnlyId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'segmentation-factors/' + data.id,
        method: 'delete'
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  }
}
