import { MutationTree } from 'vuex'
import { State } from './state'
import * as Sentry from '@sentry/vue'

import { AuthMutationTypes } from './mutation-types'
import { User } from '@/interfaces/user'

export type Mutations<S = State> = {
  [AuthMutationTypes.SET_LOGGED_IN] (state: S, loggedIn: boolean): void
  [AuthMutationTypes.SET_USER] (state: S, user: User): void
}

export const mutations: MutationTree<State> & Mutations = {
  [AuthMutationTypes.SET_LOGGED_IN] (state: State, loggedIn: boolean) {
    state.isLoggedIn = loggedIn
  },

  [AuthMutationTypes.SET_USER] (state: State, user: User) {
    state.user = user

    if (user === null) {
      return
    }

    Sentry.setUser({
      email: (typeof user?.email === 'undefined') ? null : user?.email,
      username: (typeof user?.fullName === 'undefined') ? null : user?.fullName,
      id: user['@id']
    })
  }
}
