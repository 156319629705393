import { Brick, PortfolioValue, Team, Territory, TerritoryFte } from '@/interfaces/project'
import { Segment } from '@/interfaces/segment'
import { Specialty } from '@/interfaces/specialty'
import { RootState } from '@/store'
import { GetterTree } from 'vuex'

import { State } from './state'

export type Getters = {
  specialties(state: State): Array<Specialty>
  territories(state: State): Array<Territory>
  salesTeams(state: State): Array<Team>
  bricks(state: State): Array<Brick>
  portfolioValues(state: State): Array<PortfolioValue>
  territoryFtes(state: State): Array<TerritoryFte>
  segments(state: State): Array<Segment>
  specialtiesWereChanged(state: State): boolean
  specialtiesWereUpdated(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
  specialties: (state) => state.specialties,
  territories: (state) => state.territories,
  salesTeams: (state) => state.salesTeams,
  bricks: (state) => state.bricks,
  portfolioValues: (state) => state.portfolioValues,
  territoryFtes: (state) => state.territoryFtes,
  segments: (state) => state.segments,
  specialtiesWereChanged: (state) => state.specialtiesWereChanged,
  specialtiesWereUpdated: (state) => state.specialtiesWereUpdated
}
