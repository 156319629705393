export enum DataInputMutationTypes {
    SET_SPECIALTIES = 'SET_SPECIALTIES',
    SET_TERRITORIES = 'SET_TERRITORIES',
    SET_SALES_TEAMS = 'SET_SALES_TEAMS',
    SET_BRICKS = 'SET_BRICKS',
    SET_PORTFOLIO_VALUES = 'SET_PORTFOLIO_VALUES',
    SET_SEGMENTS = 'SET_SEGMENTS',
    SET_TERRITORY_FTES = 'SET_TERRITORY_FTES',
    SET_SPECIALTIES_WERE_CHANGED = 'SET_SPECIALTIES_WERE_CHANGED',
    SET_SPECIALTIES_WERE_UPDATED = 'SET_SPECIALTIES_WERE_UPDATED'
}
