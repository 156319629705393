import { RootState } from '@/store'
import { SegmentationFactor } from '@/interfaces/segmentationfactor'
import { GetterTree } from 'vuex'
import { State } from './state'
import _ from 'lodash'

export type Getters = {
  factors(state: State): { [x: string]: SegmentationFactor[] }
}

export const getters: GetterTree<State, RootState> & Getters = {
  factors: (state) => _.groupBy(state.factors, (factor) => factor.product.id)
}
