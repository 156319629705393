import { ActionContext, ActionTree } from 'vuex'
import { State } from './state'
import { Mutations } from './mutations'
import { UserActionTypes } from './action-types'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { RootState } from '@/store'
import { UserMutationTypes } from './mutation-types'
import { OnlyId } from '../@types/entity'
import { User } from '@/interfaces/user'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [UserActionTypes.LOAD_USERS](
    { commit }: AugmentedActionContext
  ): Promise<Array<User>>
  [UserActionTypes.ADD_USER](
    { commit }: AugmentedActionContext,
    data: User
  ): Promise<User>
  [UserActionTypes.REMOVE_USER](
    { commit }: AugmentedActionContext,
    data: OnlyId
  ): Promise<boolean>
  [UserActionTypes.UPDATE_USER](
    { commit }: AugmentedActionContext,
    data: User
  ): Promise<User>,
  [UserActionTypes.LOAD_USER_DATA](
    { commit }: AugmentedActionContext,
    data: OnlyId
  ): Promise<User>
}

/* eslint-disable @typescript-eslint/no-unused-vars */

export const actions: ActionTree<State, RootState> & Actions = {
  async [UserActionTypes.LOAD_USERS] ({ commit }) {
    const res: AxiosResponse = await axios({
      url: 'users',
      method: 'get'
    })

    commit(UserMutationTypes.SET_USERS, res.data)

    return res.data
  },

  async [UserActionTypes.LOAD_USER_DATA] ({ commit }, data: OnlyId) {
    const res: AxiosResponse = await axios({
      url: 'users/' + data.id,
      method: 'get'
    })

    return res.data
  },

  async [UserActionTypes.ADD_USER] ({ commit }, data: User) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users',
        method: 'post',
        data
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [UserActionTypes.REMOVE_USER] ({ commit }, data: OnlyId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users/' + data.id,
        method: 'delete'
      }).then(() => {
        resolve(true)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [UserActionTypes.UPDATE_USER] ({ commit }, data: User) {
    return new Promise((resolve, reject) => {
      const id = data.id
      delete data.id

      axios({
        url: 'users/' + id,
        method: 'put',
        data
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  }
}
