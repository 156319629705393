import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!

  return (_openBlock(), _createBlock(_component_el_popconfirm, {
    "confirm-button-text": "Yes",
    "cancel-button-text": "No",
    width: "300",
    "hide-icon": "",
    title: _ctx.message,
    onConfirm: _ctx.callback
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_el_button, {
        size: _ctx.size ? _ctx.size : 'large',
        type: "danger"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_Delete)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["size"])
    ]),
    _: 1
  }, 8, ["title", "onConfirm"]))
}