
import { defineComponent, toRefs, reactive } from 'vue'
import { useStore } from '@/store'
import { ElMessage } from '@/../node_modules/element-plus/es/components'
import { SegmentationFactor } from '@/interfaces/segmentationfactor'
import { Product } from '@/interfaces/product'
import CustomConfirm from './partials/CustomConfirm.vue'

export default defineComponent({
  inheritAttrs: false,

  setup () {
    const store = useStore()
    const products: Array<Product> = store.getters.products
    const factorPlaceholders: { [key: string]: SegmentationFactor } = {}

    products.forEach((product) => {
      factorPlaceholders[product.id] = {
        name: '',
        weight: null
      }
    })

    let count = 0

    const factors: { [key: string]: SegmentationFactor[] } = store.getters.factors

    if (factors == null) {
      return 0
    }

    Object.keys(factors).forEach(key => {
      if (count < factors[key].length) {
        count = factors[key].length
      }
    })

    const reactiveObj = reactive({
      tooltip: false,
      activeNames: ['2'],
      index: 0,
      maxFactorCount: count,
      newFactor: factorPlaceholders
    })

    return toRefs(reactiveObj)
  },

  components: {
    CustomConfirm
  },

  watch: {
    products: function (value: Array<Product>) {
      this.products = value

      const factors = {}

      this.products.forEach((product) => {
        factors[product.id] = {
          name: '',
          weight: ''
        }
      })

      this.newFactor = factors
    },

    factors: function () {
      let count = 0

      const factors = this.factors

      if (factors == null) {
        return 0
      }

      Object.keys(factors).forEach(key => {
        if (count < factors[key].length) {
          count = factors[key].length
        }
      })

      this.maxFactorCount = count
    }
  },

  computed: {
    products (): Array<Product> {
      return this.$store.getters.products
    },

    factors (): Array<SegmentationFactor> {
      return this.$store.getters.factors
    }
  },

  methods: {
    updateFactor (row: SegmentationFactor) {
      this.$store.dispatch('UPDATE_FACTOR', {
        id: row.id,
        name: row.name,
        weight: parseInt('' + row.weight)
      }).then(() => {
        this.$store.dispatch('LOAD_FACTORS', { projectId: this.$store.getters.project.id })
          .then(() => {
            this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project.id })
            this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project.id })
            this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project.id })
            this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project.id })
          })
      })
    },

    removeFactor (factorId: number) {
      this.$store.dispatch('REMOVE_FACTOR', { id: factorId })
        .then(() => {
          this.$store.dispatch('LOAD_FACTORS', { projectId: this.$store.getters.project.id })
            .then(() => {
              this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project.id })
              this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project.id })
              this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project.id })
              this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project.id })
            })
        })
    },

    addFactor (productId: number|string) {
      if (this.newFactor[productId].name === '') {
        ElMessage.error('Factor name should not be empty')
        return
      }

      if (this.newFactor[productId].weight === '') {
        this.newFactor[productId].weight = 0
      }

      this.$store.dispatch('ADD_FACTOR', {
        ...this.newFactor[productId],
        productId
      }).then(() => {
        this.$store.dispatch('LOAD_FACTORS', { projectId: this.$store.getters.project.id })
          .then(() => {
            this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project.id })
            this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project.id })
            this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project.id })
            this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project.id })
          })

        this.newFactor[productId].name = ''
        this.newFactor[productId].weight = ''
      })
    },

    renderFactors (productId?: string): Map<number, Array<SegmentationFactor>> {
      if (typeof productId !== 'undefined') {
        return this.$store.getters.factors[productId]
      }

      return this.$store.getters.factors
    },

    factorError: function (productId: string): boolean {
      let weight = 0

      const factors: Array<SegmentationFactor> = Object.values(this.factors[productId])

      for (const factor of factors) {
        const w: number = parseInt((factor.weight).toString())
        weight += w
      }

      return weight === 100
    }
  }
})
