
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { Project, Responsibility } from '@/interfaces/project'
import { Country } from '@/interfaces/country'
import { Product, ProductWeight } from '@/interfaces/product'
import { CustomerGroup } from '@/interfaces/customergroup'
import CountryFlag from 'vue-country-flag-next'
import { ElMessage } from '@/../node_modules/element-plus/es/components'
import moment from 'moment'
import DetailingPositions from './partials/DetailingPosition.vue'
import CustomConfirm from './partials/CustomConfirm.vue'
import _ from 'lodash'
import { Responsibilities } from '@/store/modules/@types/entity'

export default defineComponent({
  inheritAttrs: false,

  setup () {
    const store = useStore()

    const reactiveObj = reactive({
      activeNames: ['2', '3'],
      index: 0,
      projectsNotFoundDialog: false,
      newProjectDialog: false,
      selectedProject: store.getters?.project?.id || undefined,
      selectedCountry: store.getters?.country?.id || undefined,

      newProject: {
        name: ''
      },

      newProduct: {
        name: '',
        notLaunched: false
      },

      newCustomerGroup: {
        name: ''
      }
    })

    return toRefs(reactiveObj)
  },

  watch: {
    selectedProjectId: function (value: string) {
      this.selectedProject = value
    },

    selectedCountryId: function (value: string) {
      this.selectedCountry = value
    },

    selectedProject: function (value: string, oldValue: string) {
      if (value === oldValue || !oldValue || !value) {
        this.selectedProject = value
        return
      }

      this.$emit('project-changed', value)
    },

    selectedCountry: function (value: string, oldValue: string) {
      if (value === oldValue || !oldValue || !value) {
        this.selectedCountry = value
        return
      }

      this.selectedProject = undefined
      this.$emit('country-changed', value)
    }
  },

  components: {
    CountryFlag,
    DetailingPositions,
    CustomConfirm
  },

  computed: {
    selectedProjectId: function (): string {
      return this.$store.getters?.project?.id || ''
    },

    selectedCountryId: function (): string {
      return this.$store.getters?.country?.id || ''
    },

    lastUpdate: function (): string {
      if (this.$store.getters.project) {
        const date = moment(this.$store.getters.project.lastUpdate)

        return date.format('dddd, MMMM Do YYYY, h:mm:ss a')
      }

      return ''
    },

    productWeights: function () {
      return _.sortBy(this.$store.getters.project?.productWeights, ['productCount'])
    },

    canUpdatePercentages: function (): boolean {
      return this.$store.getters.isUser
    }
  },

  methods: {
    getLaunchedText: function (row): string {
      return (!row.launched) ? 'Launched' : 'Not Launched'
    },

    addProject: function () {
      const data = {
        name: this.newProject.name,
        country: process.env.VUE_APP_IRI_PREFIX + 'countries/' + this.$store.getters.country.id
      }

      this.$store.dispatch('ADD_PROJECT', data)
        .then((data) => {
          this.$store.dispatch('LOAD_PROJECTS', {
            countryId: this.$store.getters.country.id
          })

          if (typeof data?.id !== 'undefined') {
            this.$emit('project-changed', data.id)
          }

          ElMessage.success('Project ' + this.newProject.name + ' was added')
          this.newProject.name = ''
          this.newProjectDialog = false
        })
    },

    updateResponsibility: function (row: Responsibility) {
      const data: Responsibilities = {
        projectId: this.$store.getters.project.id,
        projectLeader: this.$store.getters.responsibilities.projectLeader,
        regionalSupport: this.$store.getters.responsibilities.regionalSupport,
        localSupport: this.$store.getters.responsibilities.localSupport
      }

      data[_.camelCase(row.role)] = row

      this.$store.dispatch('UPDATE_RESPONSIBILITIES', data)
    },

    updateCustomerGroup: function (row: CustomerGroup) {
      this.$store.dispatch('UPDATE_CUSTOMER_GROUP', {
        customerGroupId: row.id,
        name: row.name
      }).then(() => {
        this.$store.dispatch('LOAD_CUSTOMER_GROUPS', {
          projectId: this.$store.getters.project?.id
        }).then(() => {
          this.$store.commit('SET_PRODUCT_POSITIONS', this.$store.getters.project)
          this.$store.dispatch('LOAD_SPECIALTIES', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
          this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })
        })
      })
    },

    updateProduct: function (row: Product) {
      this.$store.dispatch('UPDATE_PRODUCT', {
        productId: row.id,
        name: row.name,
        notLaunched: !row.launched
      }).then(() => {
        this.$store.dispatch('LOAD_PRODUCTS', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })
      })
    },

    updateProductWeight: function (weight: number, position: number, productCount: number) {
      this.$store.dispatch('UPDATE_PRODUCT_WEIGHTS', {
        projectId: this.$store.getters.project.id,
        productCount: parseInt('' + productCount),
        position: parseInt('' + position),
        weight: parseInt('' + weight)
      }).then(() => {
        this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project.id })
      })
    },

    checkProductWeight: function (row: ProductWeight): boolean {
      let weight = 0

      for (let i = 1; i <= 5; i++) {
        weight += parseInt('' + row['weight' + i])
      }

      return weight === 100
    },

    removeProduct: function (id: string) {
      this.$store.dispatch('REMOVE_PRODUCT', { id })
        .then(() => {
          this.$store.dispatch('LOAD_PROJECT_INFO', {
            projectId: this.$store.getters.project?.id
          }).then(() => {
            this.$store.commit('SET_PRODUCT_POSITIONS', this.$store.getters.project)
            this.$store.dispatch('LOAD_PRODUCTS', { projectId: this.$store.getters.project?.id })
            this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
            this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
            this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
            this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })
          })
        })
    },

    removeCustomerGroup: function (id: string) {
      this.$store.dispatch('REMOVE_CUSTOMER_GROUP', { id })
        .then(() => {
          this.$store.dispatch('LOAD_CUSTOMER_GROUPS', {
            projectId: this.$store.getters.project?.id
          }).then(() => {
            this.$store.commit('SET_PRODUCT_POSITIONS', this.$store.getters.project)
            this.$store.dispatch('LOAD_SPECIALTIES', { projectId: this.$store.getters.project?.id })
            this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
            this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
            this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
            this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })
          })
        })
    },

    addProduct: function () {
      if (this.newProduct.name === '') {
        ElMessage.error('Product name should not be empty')
        return
      }

      this.$store.dispatch('ADD_PRODUCT', {
        ...this.newProduct,
        projectId: this.$store.getters.project.id
      }).then(() => {
        this.$store.dispatch('LOAD_PRODUCTS', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_BRICKS', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project?.id })
        this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project?.id })

        this.newProduct.name = ''
        this.newProduct.notLaunched = false
      })
    },

    addCustomerGroup: function () {
      if (this.newCustomerGroup.name === '') {
        ElMessage.error('Customer group name should not be empty')
        return
      }

      this.$store.dispatch('ADD_CUSTOMER_GROUP', {
        ...this.newCustomerGroup,
        projectId: this.$store.getters.project.id
      }).then(() => {
        this.$store.dispatch('LOAD_CUSTOMER_GROUPS', {
          projectId: this.$store.getters.project?.id
        }).then(() => {
          this.$store.commit('SET_PRODUCT_POSITIONS', this.$store.getters.project)
          this.$store.dispatch('LOAD_SPECIALTIES', { projectId: this.$store.getters.project?.id })
        })

        this.newCustomerGroup.name = ''
      })
    },

    getCountryCode: function (projectId?: string, countryId?: string): string {
      if (typeof projectId !== 'undefined') {
        const project = this.$store.getters.projects.find((x: Project) => x.id === projectId)
        const countryCode = project?.country.alpha2Code
        return countryCode
      }

      if (typeof countryId !== 'undefined') {
        const country = this.$store.getters.countries.find((x: Country) => x.id === countryId)
        const countryCode = country.alpha2Code
        return countryCode
      }

      return ''
    }
  }
})
