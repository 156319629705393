import { Country } from '@/interfaces/country'
import { Project } from '@/interfaces/project'
import { Product } from '@/interfaces/product'
import { CustomerGroup } from '@/interfaces/customergroup'

import { MutationTree } from 'vuex'
import { GlobalMutationTypes } from './mutation-types'
import { State } from './state'

export type Mutations<S = State> = {
  [GlobalMutationTypes.SET_IS_LOADING] (state: S, isLoading: boolean): void
  [GlobalMutationTypes.SET_LOADING_TEXT] (state: S, text: string): void
  [GlobalMutationTypes.SET_COUNTRIES] (state: S, countries: Array<Country>): void
  [GlobalMutationTypes.SET_PROJECTS] (state: S, projects: Array<Project>): void
  [GlobalMutationTypes.SET_COUNTRY] (state: S, country: Country): void
  [GlobalMutationTypes.SET_PROJECT] (state: S, project: Project): void
  [GlobalMutationTypes.SET_PRODUCTS] (state: S, products: Array<Product>): void
  [GlobalMutationTypes.SET_CUSTOMER_GROUPS] (state: S, customerGroups: Array<CustomerGroup>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [GlobalMutationTypes.SET_IS_LOADING] (state: State, isLoading: boolean) {
    state.isLoading = isLoading
  },

  [GlobalMutationTypes.SET_LOADING_TEXT] (state: State, text: string) {
    state.loadingText = text
  },

  [GlobalMutationTypes.SET_PROJECTS] (state: State, projects: Array<Project>) {
    state.projects = projects
  },

  [GlobalMutationTypes.SET_COUNTRIES] (state: State, countries: Array<Country>) {
    state.countries = countries
  },

  [GlobalMutationTypes.SET_PROJECT] (state: State, project: Project) {
    state.project = project
  },

  [GlobalMutationTypes.SET_COUNTRY] (state: State, country: Country) {
    state.country = country
  },

  [GlobalMutationTypes.SET_PRODUCTS] (state: State, products: Array<Product>) {
    state.products = products
  },

  [GlobalMutationTypes.SET_CUSTOMER_GROUPS] (state: State, customerGroups: Array<CustomerGroup>) {
    state.customerGroups = customerGroups
  }
}
