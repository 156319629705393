
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from '@/store'
import { User } from '@/interfaces/user'
import _ from 'lodash'
import { ElMessage, ElMessageBox } from '@/../node_modules/element-plus/es/components'

export default defineComponent({
  name: 'UsersComponent',
  inheritAttrs: false,

  setup () {
    const store = useStore()

    const reactiveObj = reactive({
      active: ['1'],
      users: store.getters.users || [],
      userData: {},
      userEditDialog: false,
      countries: [],
      creating: false
    })

    return toRefs(reactiveObj)
  },

  mounted () {
    if (!this.$store.getters.isAdmin) {
      ElMessageBox.alert('Only administrators can access this page', 'Access Denied', {
        confirmButtonText: 'OK :-(',
        center: true,
        type: 'error',
        callback: () => {
          this.$router.replace('/generalSettings')
        }
      })
    }

    this.$store.dispatch('LOAD_USERS')
      .then(() => {
        this.users = this.$store.getters.users
      })

    this.$store.dispatch('LOAD_COUNTRIES', {
      withInactive: true
    }).then((res) => {
      this.countries = res
    })
  },

  methods: {
    userModal: function (id?: string) {
      if (typeof id !== 'undefined') {
        this.$store.dispatch('LOAD_USER_DATA', { id })
          .then((res) => {
            res.countries = res.countries.map((c) => c.id)
            if (res.role === 'ROLE_USER' && res.countries.length > 0) {
              res.countries = res.countries[0]
            }

            this.creating = false
            this.userData = res
            this.userEditDialog = true
          })
      } else {
        this.userData = {
          username: '',
          role: '',
          firstName: '',
          lastName: '',
          email: '',
          countries: ''
        }

        this.creating = true
        this.userEditDialog = true
      }
    },

    updateUser: function () {
      const userData = Object.assign({}, this.userData)
      delete userData.username

      userData.countries = userData.countries.map((c: string) => process.env.VUE_APP_IRI_PREFIX + 'countries/' + c)

      if (userData.password === '') {
        delete userData.password
      }

      this.$store.dispatch('UPDATE_USER', userData)
        .then((res) => {
          ElMessage.success('User ' + res.firstName + ' ' + res.lastName + ' was updated')
          this.userEditDialog = false
          this.userData = {}
          this.countries = []
        })
    },

    addUser: function () {
      const userData = Object.assign({}, this.userData)

      userData.countries = userData.countries.map((c: string) => process.env.VUE_APP_IRI_PREFIX + 'countries/' + c)

      this.$store.dispatch('ADD_USER', userData)
        .then((res) => {
          ElMessage.success('User ' + res.firstName + ' ' + res.lastName + ' was added to the system')
          this.users.push(res)
          this.userEditDialog = false
          this.userData = {}
          this.countries = []
        })
    },

    removeUser: function (id: string) {
      const user: User = _.find(this.users, (u) => {
        return u.id === id
      })

      if (user.role === 'ROLE_ADMIN') {
        ElMessage.error('You can\'t delete user with ADMIN ROLE!')
        return
      }

      this.$store.dispatch('REMOVE_USER', { id })
        .then(() => {
          ElMessage.success('User was deleted')
          this.users = _.filter(this.users, (u) => u.id !== id)
        })
    }
  }
})
