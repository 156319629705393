
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomConfirm',

  props: {
    callback: Function,
    message: String,
    size: String
  }
})
