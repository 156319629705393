import { SegmentationFactor } from '@/interfaces/segmentationfactor'
import { MutationTree } from 'vuex'
import { SegmentationTargetingMutationTypes } from './mutation-types'
import { State } from './state'

export type Mutations<S = State> = {
  [SegmentationTargetingMutationTypes.SET_FACTORS] (state: S, factors: Array<SegmentationFactor>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [SegmentationTargetingMutationTypes.SET_FACTORS] (state: State, factors: Array<SegmentationFactor>) {
    state.factors = factors
  }
}
