import { ActionContext, ActionTree } from 'vuex'

import { State } from './state'
import { Mutations } from './mutations'
import { GeneralSettingsActionTypes } from './action-types'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { RootState } from '@/store'
import { OnlyId, NewProduct, Product, CustomerGroup, NewCustomerGroup, ProductPosition, ProductWeight, Responsibilities, NewProject } from '../@types/entity'
import { GeneralSettingsMutationTypes } from './mutation-types'
import { Project } from '@/interfaces/project'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [GeneralSettingsActionTypes.REMOVE_PRODUCT](
    { commit }: AugmentedActionContext,
    data: OnlyId
  ): Promise<boolean>,
  [GeneralSettingsActionTypes.REMOVE_CUSTOMER_GROUP](
    { commit }: AugmentedActionContext,
    data: OnlyId
  ): Promise<boolean>,
  [GeneralSettingsActionTypes.ADD_PRODUCT](
    { commit }: AugmentedActionContext,
    data: NewProduct
  ): Promise<void | Product>,
  [GeneralSettingsActionTypes.ADD_CUSTOMER_GROUP](
    { commit }: AugmentedActionContext,
    data: NewCustomerGroup
  ): Promise<void | CustomerGroup>,
  [GeneralSettingsActionTypes.UPDATE_PRODUCT_POSITION](
    { commit }: AugmentedActionContext,
    data: ProductPosition
  ): Promise<Project>,
  [GeneralSettingsActionTypes.UPDATE_PRODUCT](
    { commit }: AugmentedActionContext,
    data: Product
  ): Promise<void>,
  [GeneralSettingsActionTypes.UPDATE_PRODUCT_WEIGHTS](
    { commit }: AugmentedActionContext,
    data: ProductWeight
  ): Promise<void>,
  [GeneralSettingsActionTypes.ADD_PROJECT](
    { commit }: AugmentedActionContext,
    data: NewProject
  ): Promise<Project>
}

/* eslint-disable @typescript-eslint/no-unused-vars */

export const actions: ActionTree<State, RootState> & Actions = {
  async [GeneralSettingsActionTypes.REMOVE_PRODUCT] ({ commit }, data: OnlyId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'products/' + data.id,
        method: 'delete'
      }).then(() => {
        resolve(true)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.REMOVE_CUSTOMER_GROUP] ({ commit }, data: OnlyId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'customer-groups/' + data.id,
        method: 'delete'
      }).then(() => {
        resolve(true)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.ADD_PRODUCT] ({ commit }, data: NewProduct) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'products',
        method: 'post',
        data: {
          name: data.name,
          isLaunched: !data.notLaunched,
          project: process.env.VUE_APP_IRI_PREFIX + 'projects/' + data.projectId
        }
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.ADD_CUSTOMER_GROUP] ({ commit }, data: NewCustomerGroup) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'customer-groups',
        method: 'post',
        data: {
          name: data.name,
          project: process.env.VUE_APP_IRI_PREFIX + 'projects/' + data.projectId
        }
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.UPDATE_PRODUCT_POSITION] ({ commit }, data: ProductPosition) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'projects/' + data.projectId + '/product-position',
        method: 'put',
        data: {
          position: data.position,
          customerGroup: process.env.VUE_APP_IRI_PREFIX + 'customer-groups/' + data.customerGroupId,
          product: (data.productId !== null)
            ? process.env.VUE_APP_IRI_PREFIX + 'products/' + data.productId
            : null
        }
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.UPDATE_PRODUCT] ({ commit }, data: Product) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'products/' + data.productId,
        method: 'put',
        data: {
          name: data.name,
          isLaunched: data.notLaunched
        }
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.UPDATE_CUSTOMER_GROUP] ({ commit }, data: CustomerGroup) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'customer-groups/' + data.customerGroupId,
        method: 'put',
        data: {
          name: data.name
        }
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.UPDATE_PRODUCT_WEIGHTS] ({ commit }, data: ProductWeight) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'projects/' + data.projectId + '/product-weight',
        method: 'put',
        data: {
          productCount: parseInt('' + data.productCount),
          position: parseInt('' + data.position),
          weight: parseInt('' + data.weight)
        }
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.UPDATE_RESPONSIBILITIES] ({ commit }, data: Responsibilities) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'projects/' + data.projectId,
        method: 'put',
        data: {
          projectLeader: data.projectLeader,
          regionalSupport: data.regionalSupport,
          localSupport: data.localSupport
        }
      }).then((res: AxiosResponse) => {
        commit(GeneralSettingsMutationTypes.SET_PROJECT, res.data)
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  },

  async [GeneralSettingsActionTypes.ADD_PROJECT] ({ commit }, data: NewProject) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'projects',
        method: 'post',
        data
      }).then((res: AxiosResponse) => {
        resolve(res.data)
      }).catch((error: AxiosError) => {
        reject(error.message)
      })
    })
  }
}
