import { ProductPosition } from '@/interfaces/project'
import { Dictionary } from 'lodash'

export type State = {
  detailingPositions: { [x: string]: Dictionary<ProductPosition> }
}

export const state: State = {
  detailingPositions: {}
}
