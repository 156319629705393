
import { defineComponent, reactive, toRefs } from 'vue'
import AuthComponent from './components/AuthComponent.vue'
import { Country } from '@/interfaces/country'
import { Project } from '@/interfaces/project'
import _ from 'lodash'

export default defineComponent({
  components: {
    AuthComponent
  },

  setup () {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation()
      }
    })

    const reactiveObj = reactive({
      isCollapse: false
    })

    return {
      ...toRefs(reactiveObj)
    }
  },

  beforeCreate: function () {
    this.$store.commit('SET_IS_LOADING', true)
    this.$store.dispatch('CHECK_JWT')
      .then(() => {
        this.$router.replace('/generalSettings')
        this.afterLogin()
      })
  },

  computed: {
    isLoggedIn: function (): boolean {
      return this.$store.getters.isLoggedIn || false
    },

    collapseText: function () {
      let selected = this.$store.getters?.project?.name ?? ''

      selected += ' | '
      selected += this.$store.getters?.country?.name ?? ''

      return selected
    }
  },

  watch: {
    isLoggedIn: function (value) {
      if (value) {
        this.afterLogin()
      }
    },

    $route: function () {
      if (this.$store.getters?.specialtiesWereChanged === true) {
        this.$store.dispatch('UPDATE_SPECIALTIES', {
          projectId: this.$store.getters.project.id
        })
      }
    }
  },

  methods: {
    afterLogin: function () {
      this.$store.commit('SET_IS_LOADING', true)

      this.$store.dispatch('LOAD_COUNTRIES', {
        withInactive: false
      }).then(() => {
        let country = this.$store.getters.countries[0]

        // Country was saved in localStorage
        if (typeof localStorage.selectedCountryId !== 'undefined') {
          const countryFind = _.find(this.$store.getters.countries, (c: Country) => {
            return c.id === localStorage.selectedCountryId
          })

          if (typeof countryFind !== 'undefined' && countryFind) {
            country = countryFind
          }
        }

        this.$store.commit('SET_COUNTRY', country)
        this.countryWasChanged(this.$store.getters.country.id, true)
      })
    },

    countryWasChanged: function (countryId: string, onLoad?: boolean) {
      this.$store.commit('SET_IS_LOADING', true)
      if (typeof onLoad === 'undefined' || !onLoad) {
        const country = _.find(this.$store.getters.countries, (c: Country) => {
          return c.id === countryId
        })

        this.$store.commit('SET_COUNTRY', country)
        localStorage.selectedCountryId = countryId
      }

      this.$store.dispatch('LOAD_PROJECTS', {
        countryId
      }).then(() => {
        let project = this.$store.getters.projects[0] || null

        // Project was saved in localStorage
        if (typeof onLoad !== 'undefined' && onLoad && typeof localStorage.selectedProjectId !== 'undefined') {
          const projectFind = _.find(this.$store.getters.projects, (p: Project) => {
            return p.id === localStorage.selectedProjectId
          })

          if (typeof projectFind !== 'undefined' && projectFind) {
            project = projectFind.id
          }
        }

        if (typeof project !== 'undefined' && project) {
          const projectId = (typeof project === 'string') ? project : project.id

          this.projectWasChanged(projectId)
        } else {
          this.$store.commit('SET_CUSTOMER_GROUPS', [])
          this.$store.commit('SET_SPECIALTIES', [])
          this.$store.commit('SET_SALES_TEAMS', [])
          this.$store.commit('SET_TERRITORIES', [])
          this.$store.commit('SET_BRICKS', [])
          this.$store.commit('SET_TERRITORY_FTES', [])
          this.$store.commit('SET_SEGMENTS', [])
          this.$store.commit('SET_PRODUCTS', [])
          this.$store.commit('SET_FACTORS', [])
          localStorage.selectedProjectId = undefined
          this.$store.commit('SET_IS_LOADING', false)
        }
      })
    },

    projectWasChanged: function (projectId: string) {
      localStorage.selectedProjectId = projectId

      this.$store.dispatch('LOAD_PROJECT_INFO', {
        projectId
      }).then((res: Project) => {
        Promise.all([
          this.$store.dispatch('LOAD_CUSTOMER_GROUPS', { projectId: res.id }),
          this.$store.dispatch('LOAD_SPECIALTIES', { projectId: res.id }),
          this.$store.dispatch('LOAD_SALES_TEAMS', { projectId: res.id }),
          this.$store.dispatch('LOAD_TERRITORIES', { projectId: res.id }),
          this.$store.dispatch('LOAD_BRICKS', { projectId: res.id }),
          this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: res.id }),
          this.$store.dispatch('LOAD_SEGMENTS', { projectId: res.id }),
          this.$store.dispatch('LOAD_PRODUCTS', { projectId: res.id }),
          this.$store.dispatch('LOAD_FACTORS', { projectId: res.id })
        ]).finally(() => {
          this.$store.commit('SET_PRODUCT_POSITIONS', res)
          this.$store.commit('SET_IS_LOADING', false)
        })
      })
    }
  }
})
