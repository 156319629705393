
import { defineComponent, reactive, toRefs } from 'vue'
import { Product } from '@/interfaces/product'
import { CustomerGroup } from '@/interfaces/customergroup'
import _ from 'lodash'

export default defineComponent({
  setup () {
    const reactiveObj = reactive({
      value: null
    })

    return toRefs(reactiveObj)
  },

  methods: {
    canBeNone: function (customerGroup: CustomerGroup, position: number) {
      const positions = this.$store.getters.detailingPositions[customerGroup.id]

      if (position === 5) {
        return true
      }

      if (positions[position + 1].id !== null) {
        return false
      }

      return true
    },

    canBeOpened: function (customerGroup: CustomerGroup, position: number) {
      const positions = this.$store.getters.detailingPositions[customerGroup.id]

      if (position === 1) {
        return true
      }

      if (positions[position - 1].id === null || positions[position - 1].id === 'null') {
        return false
      }

      return true
    },

    canBeSelected: function (product: Product, customerGroup: CustomerGroup) {
      const positions = this.$store.getters.detailingPositions[customerGroup.id]
      let result = true

      _.forEach(positions, (position) => {
        if (position.id !== null && position.product && position.product.id === product.id) {
          result = false
        }
      })

      return result
    },

    changeProductPosition: function (productId: string, customerGroupId: string, position: number) {
      this.$store.dispatch('UPDATE_PRODUCT_POSITION', {
        projectId: this.$store.getters?.project?.id,
        productId: (productId === 'null') ? null : productId,
        customerGroupId,
        position
      }).then((res) => {
        this.$store.commit('SET_PROJECT', res)
        this.$store.commit('SET_PRODUCT_POSITIONS', res)
        this.$store.dispatch('LOAD_PORTFOLIO_VALUES', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_TERRITORY_FTES', { projectId: this.$store.getters.project.id })
        this.$store.dispatch('LOAD_SEGMENTS', { projectId: this.$store.getters.project.id })
      })
    }
  }
})
