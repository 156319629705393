import { User } from '@/interfaces/user'

export type State = {
  isLoggedIn: boolean
  user: User | null
}

export const state: State = {
  isLoggedIn: false,
  user: null
}
