import { RootState } from '@/store'
import { User } from '@/interfaces/user'
import { GetterTree } from 'vuex'

import { State } from './state'

export type Getters = {
  isLoggedIn(state: State): boolean
  user(state: State): User | null
  isUser(state: State): boolean
  isManager(state: State): boolean
  isAdmin(state: State): boolean
  userId(state: State): string
}

export const getters: GetterTree<State, RootState> & Getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  user: (state) => state.user,
  isUser: (state) => state.user?.role === 'ROLE_USER',
  isManager: (state) => state.user?.role === 'ROLE_MANAGER',
  isAdmin: (state) => state.user?.role === 'ROLE_ADMIN',
  userId: (state) => state.user?.id
}
