export enum DataInputActionTypes {
  LOAD_SPECIALTIES = 'LOAD_SPECIALTIES',
  UPDATE_SPECIALTY = 'UPDATE_SPECIALTY',
  LOAD_SALES_TEAMS = 'LOAD_SALES_TEAMS',
  LOAD_TERRITORIES = 'LOAD_TERRITORIES',
  LOAD_BRICKS = 'LOAD_BRICKS',
  LOAD_PORTFOLIO_VALUES = 'LOAD_PORTFOLIO_VALUES',
  LOAD_PORTFOLIO_VALUES_FILTERED = 'LOAD_PORTFOLIO_VALUES_FILTERED',
  LOAD_TERRITORY_FTES = 'LOAD_TERRITORY_FTES',
  LOAD_TERRITORY_FTES_FILTERED = 'LOAD_TERRITORY_FTES_FILTERED',
  LOAD_SEGMENTS = 'LOAD_SEGMENTS',
  LOAD_SEGMENTS_FILTERED = 'LOAD_SEGMENTS_FILTERED',
  ADD_SEGMENT = 'ADD_SEGMENT',
  UPDATE_SEGMENT = 'UPDATE_SEGMENT',
  DELETE_SEGMENT = 'DELETE_SEGMENT',
  DELETE_DATA = 'DELETE_DATA',
  UPDATE_SPECIALTIES = 'UPDATE_SPECIALTIES'
}
