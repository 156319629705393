import * as VueRouter from 'vue-router'
import GeneralSettings from '../components/GeneralSettings.vue'
import SegmentationTargeting from '../components/SegmentationTargeting.vue'
import DataInput from '../components/DataInput.vue'
import FteCapacity from '../components/FteCapacity.vue'
import TotalPortfolioValue from '../components/TotalPortfolioValue.vue'
import UsersComponent from '../components/UsersComponent.vue'
import ChangelogComponent from '../components/ChangelogComponent.vue'

const routes = [
  {
    path: '/',
    component: GeneralSettings
  },
  {
    path: '/generalSettings',
    component: GeneralSettings
  },
  {
    path: '/segmentationTargeting',
    component: SegmentationTargeting
  },
  {
    path: '/dataInput',
    component: DataInput
  },
  {
    path: '/fteCapacity',
    component: FteCapacity
  },
  {
    path: '/totalPortfolioValue',
    component: TotalPortfolioValue
  },
  {
    path: '/users',
    component: UsersComponent
  },
  {
    path: '/changelog',
    component: ChangelogComponent
  }
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes
})

export default router
