import { Brick, PortfolioValue, Team, Territory, TerritoryFte } from '@/interfaces/project'
import { Segment } from '@/interfaces/segment'
import { Specialty } from '@/interfaces/specialty'

export type State = {
  specialties: Array<Specialty>
  territories: Array<Territory>
  salesTeams: Array<Team>
  bricks: Array<Brick>
  portfolioValues: Array<PortfolioValue>
  territoryFtes: Array<TerritoryFte>
  segments: Array<Segment>,
  specialtiesWereChanged: boolean,
  specialtiesWereUpdated: boolean
}

export const state: State = {
  specialties: [],
  territories: [],
  salesTeams: [],
  bricks: [],
  portfolioValues: [],
  territoryFtes: [],
  segments: [],
  specialtiesWereChanged: false,
  specialtiesWereUpdated: true
}
