import { User } from '@/interfaces/user'
import { MutationTree } from 'vuex'
import { UserMutationTypes } from './mutation-types'
import { State } from './state'

export type Mutations<S = State> = {
  [UserMutationTypes.SET_USERS] (state: S, users: Array<User>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.SET_USERS] (state: State, users: Array<User>) {
    state.users = users['hydra:member']
  }
}
